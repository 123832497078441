import { combineReducers } from "redux";
import authReducer from "./slices/auth";
import propertyReducer from "./slices/property";
import messageReducer from "./slices/message";
import userReducer from "./slices/user";

export const rootReducer = combineReducers({
  auth: authReducer,
  property: propertyReducer,
  message: messageReducer,
  user: userReducer,
});
