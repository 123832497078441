import React from "react";
import _ from "lodash";
import IconInchTape from "../../assets/image/Icon (2).png";
import { Link } from "react-router-dom";
import IconBed from "../../assets/image/Icon.png";
import IconBath from "../../assets/image/Icon (1).png";
import Vector from "../../assets/image/Vector.png";
const MobileViewPropertyCard = ({ item }) => {
  // const item = {
  //   title: "Mobile Top View CAR",
  //   price: "200",
  //   baths: 3,
  //   bedrooms: 3,
  //   area: "30 marla",
  // };
  return (
    <Link
      to={item?.link}
      className=" items-center bg-transparent mx-3 w-[180px] rounded-lg shadow  hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
    >
      <img
        className="object-cover rounded-t-lg md:h-auto md:rounded-none sm:rounded-s-lg"
        src={item?.property_images?.urls[0]?.url}
        alt=""
      />
      <div className="flex flex-col justify-between p-2 leading-normal">
        <p className=" font-normal text-xs text-gray-700 dark:text-gray-400"></p>
        <div className="">
          {item?.title && (
            <h5 className=" text-md font-bold tracking-tight text-gray-900 dark:text-white">
              {item?.title}
            </h5>
          )}
          {item?.area && (
            <div className="flex">
              <span className="pt-1 pe-3">
                <img src={IconInchTape} alt="vector" />
              </span>
              <span>
                <span className="font-extrabold text-xs">
                  {" "}
                  {_.toString(item?.area).slice(0, 20)}
                  {item?.area_type}
                </span>
              </span>
            </div>
          )}
          {item?.description && (
            <div className="flex">
              <span className="pt-1 pe-3">
                <img src={Vector} alt="vector" />
              </span>
              <span className="text-sm">
                {_.toString(item?.description).slice(0, 30)}...
              </span>
            </div>
          )}
          {item?.price && (
            <div className="">
              <span className=" font-extrabold font-mono text-xl text-green-700">
                PKR {item?.price}
              </span>
            </div>
          )}
          <div className="flex gap-3 text-sm flex-wrap">
            {item?.bedrooms && (
              <div className="flex ">
                <span className="pt-1 pe-3">
                  <img src={IconBed} alt="vector" />
                </span>
                <span>
                  <span className="font-extrabold">{item?.bedrooms}</span>
                </span>
              </div>
            )}
            {item?.baths && (
              <div className="flex">
                <span className="pt-1 pe-3">
                  <img src={IconBath} alt="vector" />
                </span>
                <span>
                  <span className="font-extrabold">{item?.baths}</span>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default MobileViewPropertyCard;
