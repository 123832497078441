import React from "react";
import ListCard from "../Card/ListCard";
const ListView = ({ items }) => {
  return (
    <div>
      <div className="grid sm:grid-cols-1 gap-5 p-5">
        {items?.map((item, index) => (
          <ListCard item={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default ListView;
