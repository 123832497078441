import React, { useEffect, useMemo, useState } from "react";
import Vector from "../../assets/image/Vector.png";
import Pagination from "../Pagination/Pagination";
import { Link } from "react-router-dom";

const Table = ({ tableListings, tableHeadings, property }) => {
  const [data, setData] = useState([]);
  console.log("🚀 ~ Table ~ data:", data);
  const [data_, setData_] = useState([]);
  console.log("🚀 ~ Table ~ data_:", data_);
  useMemo(() => {
    setData(tableListings);
    setData_(tableListings);
  }, [tableListings]);
  const handleDataFromPagination = (data) => {
    setData(data);
  };
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-800 dark:text-gray-400">
        <thead className="text-md font-extrabold text-gray-100 uppercase bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr className="font-extrabold">
            {tableHeadings?.map((head, index) => (
              <th scope="col" className="px-6 py-3 text-white">
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <>
              {property ? (
                <tr key={index} className="bg-white dark:bg-gray-800  ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <Link to={`/property-details/${item.id}`}>
                      <div className="flex items-center bg-white rounded-lg shadow md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                        <img
                          className="object-cover w-[200px] rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
                          src={
                            item.imageUrl || "https://via.placeholder.com/200"
                          }
                          alt={item.title}
                        />
                        <div className="p-5 rounded-md bg-white h-fit w-full">
                          <h1 className="text-md font-extrabold pb-4">
                            {item.title || "No title available"}
                          </h1>
                          <div className="flex">
                            <span className="pt-1 pe-3">
                              <img src={Vector} alt="vector" />
                            </span>
                            <span className="text-sm">
                              Posted: {item.postedDate}
                            </span>
                          </div>
                          <div className="py-2">
                            <span className="font-extrabold text-2xl text-[#006aff]">
                              ${item.price || "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="border border-gray-300 mt-2"></div>
                      </div>
                    </Link>
                  </th>
                  <td className="px-6 py-4">
                    <div
                      className={`w-fit text-xs font-medium me-2 px-2.5 py-0.5 rounded 
                    ${
                      item.status === "Approved"
                        ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300"
                        : item.status === "Pending"
                        ? "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300"
                        : "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"
                    }`}
                    >
                      {item.status}
                    </div>
                  </td>
                </tr>
              ) : (
                <tr key={index} className="bg-white dark:bg-gray-800  ">
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item?.name}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item?.email}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item?.phone}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item?.message}
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
      <div>
        <Pagination
          itemsPerPage={3}
          items={data_}
          dataFromChild={handleDataFromPagination}
        />
      </div>
    </div>
  );
};

export default Table;
