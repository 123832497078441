import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Heading } from "../Heading";
import _ from "lodash";
import GridCard from "../Card/GridCard";
import "./carousel.css";
import MobileViewPropertyCard from "../Card/MobileViewPropertyCard";

const Carousel = ({
  items,
  title,
  carouselType,
  smallItems,
  mediumItems,
  largeItems,
  autoPlay,
  inlinePadding,
  disableDotsControls,
  thumbnails,
  background,
  mobile,
  headingPadding,
  description,
  disableAutoPlay,
  headingColor,
  disableInfinite,
  showNavigation,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleSlideChange = (event) => {
    setActiveIndex(event.item);
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };
  const responsive = {
    0: { items: smallItems || 1 },
    568: { items: mediumItems || 2 },
    1024: { items: largeItems || 3, itemsFit: "cover" },
  };

  return (
    <div className={`bg-[${background}] ${inlinePadding ? "lg:px-52" : ""}`}>
      {title && (
        <div
          className={`${headingPadding ? "py-14" : ""} text-${
            headingColor ? headingColor : "white"
          }`}
        >
          <Heading title={title} size="heading5xl" className={`text-center `} />
          {description && <p className=" text-center">{description}</p>}
        </div>
      )}
      <AliceCarousel
        mouseTracking
        responsive={responsive}
        autoPlay={disableAutoPlay ? false : true}
        infinite={disableInfinite ? false : true}
        autoPlayInterval={3000}
        controlsStrategy="responsive"
        keyboardNavigation={false}
        animationDuration={800}
        disableButtonsControls={showNavigation === false ? true : false}
        disableDotsControls={disableDotsControls ? disableDotsControls : false}
        onSlideChanged={handleSlideChange}
      >
        {items?.map((item, index) => (
          <div className="px-auto">
            {carouselType === "image" ? (
              <img
                src={item?.url}
                alt={index}
                className="w-full max-h-[600px] rounded-t-lg"
              />
            ) : mobile ? (
              <MobileViewPropertyCard key={index} item={item} />
            ) : (
              <GridCard item={item} key={index} />
            )}
          </div>
        ))}
      </AliceCarousel>

      {thumbnails && (
        <div className="flex justify-center mt-0">
          {items?.map((item, index) => (
            <div
              key={index}
              className={` rounded-lg cursor-pointer mx-2 ${
                index === activeIndex ? "border-2 border-blue-500" : ""
              }`}
              onClick={() => handleThumbnailClick(index)}
            >
              {carouselType === "image" ? (
                <img
                  className="w-20 sm:h-10 lg:h-14 object-cover rounded-lg"
                  src={item?.url}
                  alt={`thumbnail-${index}`}
                />
              ) : (
                <GridCard item={item} className="w-20 h-20" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Carousel;
