import React from "react";
import GridCard from "../Card/GridCard";
const GridView = ({ items, cols, paddingInline, paddingEnd }) => {
  return (
    <div
      className={`grid lg:grid-cols-${cols} md:grid-cols-2 sm:grid-cols-1 gap-5`}
      style={{ paddingInline: paddingInline, paddingEnd: paddingEnd }}
    >
      {items?.map((item, index) => (
        <GridCard item={item} key={index} />
      ))}
    </div>
  );
};

export default GridView;
