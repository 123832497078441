import React from "react";
import Header from "./components/Header";
import { Heading } from "../../../../components/Heading";
import GridCard from "../../../../components/Card/GridCard";
import { useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";

const PropertyDetails = ({ propertyDetails }) => {
  const { properties } = useSelector((state) => state.property);
  const handleCardClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      <Header />
      <div className="">
        <div className="" id="detail_section_one">
          <Heading size="heading3xl" className="bg-gray-100 px-5 py-3">
            Overview
          </Heading>
          <Heading className="px-5 py-4" size="headingxl">
            Details
          </Heading>
          <div className="grid lg:grid-cols-2 md:grid-col-1 sm:grid-col-1 gap-5">
            <div>
              <div className="bg-gray-100 flex gap-10 px-5 py-5">
                <span className="w-20">Type</span>
                <span>Residential Plot</span>
              </div>
              <div className=" flex gap-10 px-5 py-5">
                <span className="w-20">Price</span>
                <span>{propertyDetails?.price}</span>
              </div>
              <div className="bg-gray-100 flex gap-10 px-5 py-5">
                <span className="w-20">Location</span>
                <span>{propertyDetails?.location}</span>
              </div>
              <div className=" flex gap-10 px-5 py-5">
                <span className="w-20">Bath(s)</span>
                <span>{propertyDetails?.baths}</span>
              </div>
            </div>
            <div>
              <div className="bg-gray-100 flex gap-10 px-5 py-5">
                <span className="w-20">Area</span>
                <span>{propertyDetails?.area}</span>
              </div>
              <div className=" flex gap-10 px-5 py-5">
                <span className="w-20">Purpose</span>
                <span>Residential Plot</span>
              </div>
              <div className="bg-gray-100 flex gap-10 px-5 py-5">
                <span className="w-20">Bedroom(s)</span>
                <span>{propertyDetails?.bathrooms}</span>
              </div>
              <div className=" flex gap-10 px-5 py-5">
                <span className="w-20">Added</span>
                <span>4 weeks ago</span>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 py-5" id="detail_section_two">
          <Heading className="px-5" size="headingxl">
            Description
          </Heading>
          <div className="py-5 px-5 rounded-lg">
            {propertyDetails?.description}
          </div>
        </div>
        <div className="bg-gray-100 p-5 mt-5" id="detail_section_three">
          <Heading className="px-5 py-4" size="headingxl">
            Amenities
          </Heading>
          <div className="grid gap-5 lg:grid-cols-3 sm:grid-cols-2">
            {propertyDetails?.amenities?.main_features?.map((key, index) => (
              <div key={index} className=" text-md font-extrabold">
                <span className="flex bg-white w-fit p-2 rounded-lg">
                  <span className="pt-1 px-2 border-e-2 border-gray-200">
                    <FaCheck color="green" />
                  </span>
                  <span className="px-3">{key}</span>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="" id="detail_section_four">
          <div className="lg:px-12 my-10">
            <div className="mb-10">
              <Heading
                title="Similar Plots"
                size="heading6xl"
                className="text-center"
              />
              <p className="text-sm text-center">
                Explore similar properties that match your preferences and
                needs.
              </p>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-5">
              {properties?.map((item, index) => (
                <div key={index} onClick={handleCardClick}>
                  <GridCard item={item} key={index} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
