import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer/Footer";

const Layout = ({ pageTitle, pageDescription, component }) => {
  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Navbar />
      {component}
      <Footer />
    </div>
  );
};

export default Layout;
