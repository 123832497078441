import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

const SmallCard = ({ item }) => {
  return (
    <div className="">
      <Link to={item?.link}>
        <div className="flex border border-gray-200 gap-5 relative shadow-lg w-full h-fit rounded-xl  bg-white m-3">
          <div>
            {item?.property_images?.urls && (
              <img
                src={item?.property_images?.urls[0]?.url}
                alt="new"
                className="rounded-s-lg border border-purple-800"
                style={{ height: "80px", width: "150px" }}
              />
            )}
          </div>
          <div className=" text-start rounded-md bg-white w-full">
            <div>
              {item?.title && (
                <h1 className="text-sm font-semibold pt-3">
                  {_.toString(item?.title).slice(0, 28)}
                </h1>
              )}
              {/* {item?.description && (
                <div className="text-xs">
                  <p>{_.toString(item?.description)?.slice(0, 80)}</p>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SmallCard;
