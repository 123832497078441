import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { FaBell, FaUser } from "react-icons/fa";
import { Bars3Icon, XMarkIcon, UserIcon } from "@heroicons/react/24/outline";
import Modal from "../Modal/Modal";
import SignInForm from "../SignInForm/SignInForm";
import RegisterForm from "../RegisterForm/RegisterForm";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/services/auth";
import { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/3.png";
import OTPForm from "../OTPForm/OTPForm";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar({ showBrand }) {
  const location = useLocation();

  const navigation = [
    {
      name: "Home",
      href: "/",
      current: location?.pathname === "/" ? true : false,
    },
    {
      name: "Properties",
      href: "/properties",
      current: location?.pathname === "/properties" ? true : false,
    },
    {
      name: "Contact",
      href: "/contact",
      current: location?.pathname === "/contact" ? true : false,
    },
    {
      name: "Pricing",
      href: "/pricing",
      current: location?.pathname === "/pricing" ? true : false,
    },
  ];
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.auth);
  const logout = useCallback(() => {
    dispatch(logoutUser(token));
  }, [dispatch, token]);
  const userNavigation = [
    { type: "link", name: "Dashbord", href: "/dashboard" },
    {
      type: "button",
      name: "Sign out",
      onClick: () => {
        logout(token);
      },
    },
  ];
  return (
    <>
      <div className="min-h-full">
        <Disclosure
          as="nav"
          className="bg-slate-50 shadow border border-gray-300"
        >
          <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center">
              <div className="flex items-center justify-between w-full">
                <Link to={"/"} className="flex-shrink-0">
                  {showBrand === false ? (
                    ""
                  ) : (
                    <img alt="Your Company" src={Logo} className="w-[150px]" />
                  )}
                </Link>
                <div className="hidden md:block mx-auto">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "border-b-2 border-[#006aff] text-black"
                            : "text-black px-3",
                          " text-md font-medium"
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                {user?.id ? (
                  <div className="ml-4 flex items-center md:ml-6">
                    <button
                      type="button"
                      className="relative rounded-full bg-[#006aff] p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <FaBell
                        aria-hidden="true"
                        className="h-[29px] w-[29px] p-1"
                        color="white"
                      />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <FaUser className="h-9 p-2 w-9" color="white" />
                        </MenuButton>
                      </div>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        {userNavigation.map((item) => (
                          <>
                            {item?.type === "button" && (
                              <MenuItem key={item.name}>
                                <button
                                  className="w-full text-left block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                                  onClick={item.onClick}
                                >
                                  {item.name}
                                </button>
                              </MenuItem>
                            )}
                            {item?.type === "link" && (
                              <MenuItem key={item.name}>
                                <Link
                                  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                                  to={item?.href}
                                >
                                  {item?.name}
                                </Link>
                              </MenuItem>
                            )}
                          </>
                        ))}
                      </MenuItems>
                    </Menu>
                  </div>
                ) : (
                  <div className="ml-4 flex items-center md:ml-6 gap-1">
                    <span>
                      <FaUser aria-hidden="true" className="h-6 w-6 mx-1" />
                    </span>
                    {/* <div className="hidden"> */}

                    {/* </div> */}
                    <Modal
                      buttonID={"register_modal"}
                      title={"Register"}
                      content={<RegisterForm />}
                    />

                    <span> / </span>
                    <Modal
                      buttonID={"login_modal"}
                      title={"Login"}
                      content={<SignInForm />}
                    />
                  </div>
                )}
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-[#006aff] p-2 text-gray-400 hover:bg-[#006aff] hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon
                    aria-hidden="true"
                    className="block h-6 w-6 group-data-[open]:hidden"
                  />
                  <XMarkIcon
                    aria-hidden="true"
                    className="hidden h-6 w-6 group-data-[open]:block"
                  />
                </DisclosureButton>
              </div>
            </div>
          </div>

          <DisclosurePanel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) => (
                <DisclosureButton
                  key={item.name}
                  // as="a"
                  // href={item.href}
                  aria-current={item.current ? "page" : undefined}
                  className={classNames(
                    item.current
                      ? "bg-[#006aff] text-white w-full text-left"
                      : "text-black hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                >
                  <Link to={item.href}>{item.name}</Link>
                </DisclosureButton>
              ))}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              {user?.id ? (
                <>
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        alt=""
                        src={
                          "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
                        }
                        className="h-10 w-10 rounded-full"
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-black">
                        {user.name}
                      </div>
                      <div className="text-sm font-medium leading-none text-black">
                        {user.email}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <FaBell
                        aria-hidden="true"
                        className="h-8 w-8 p-1"
                        color="white"
                      />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-black hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </div>
                </>
              ) : (
                <div className="ml-4 flex items-center md:ml-6">
                  <span>
                    <UserIcon aria-hidden="true" className="h-6 w-6" />
                  </span>
                  <Modal title={"Register"} content={<RegisterForm />} />

                  <span> / </span>
                  <Modal title={"Login"} content={<SignInForm />} />
                </div>
              )}
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </>
  );
}
