import React from "react";
import NewsletterForm from "../NewsletterForm/NewsletterForm";
import Logo from "../../assets/image/Logo.png";
import Modal from "../Modal/Modal";
import ContactSeller from "../ContactSellerForm/ContactSeller";
const Footer = () => {
  return (
    <footer
      class=" bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')]
       dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')] "
    >
      <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-10 py-20 lg:px-44">
        <div>
          <div className="bg-[#FFF5E0] mx-auto flex gap-5 rounded-xl  p-10">
            <div className="h-20 bg-slate-300 w-32 rounded-md">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRbcrj53mGyk-u4JwrIb6z1RBAeCpxR78gfQ&s"
                alt=""
                className="h-[90px] rounded-lg shadow-lg w-[200px]"
              />
            </div>{" "}
            <div>
              <h1 className="text-3xl font-extrabold">You need a house</h1>
              <p>
                Tell us your needs, we will give you thousands of suggestions
                for the dream home.
              </p>
            </div>
          </div>
          <button
            type="submit"
            className="flex gap-4 text-white rounded-lg lg:w-1/3 mx-auto relative sm:-top-7 mt-2 justify-center bg-[#006aff] px-4 py-4 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-300 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <Modal
              content={<ContactSeller />}
              buttonID={"contact_seller_modal"}
              title={"Contact Seller"}
            />{" "}
          </button>{" "}
        </div>
        <div>
          <div className="bg-[#FFF5E0] mx-auto flex gap-5 rounded-xl  p-10">
            <div className="h-20 bg-slate-300 w-32 rounded-md">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRncBeNrPC2tBJwQ4eiQLgirurq-_ejYTpO2g&s"
                alt=""
                className="h-[90px] rounded-lg shadow-lg w-[200px]"
              />
            </div>
            <div>
              <h1 className="text-3xl font-extrabold">You need a Plot</h1>
              <p>
                Tell us your needs, we will give you thousands of suggestions
                for the dream home.
              </p>
            </div>
          </div>
          <button
            type="submit"
            className="flex gap-4 text-white rounded-lg lg:w-1/3 mx-auto relative sm:-top-7 mt-2 justify-center bg-[#006aff] px-4 py-4 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-300 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <Modal
              content={<ContactSeller />}
              buttonID={"contact_seller_modal"}
              title={"Contact Seller"}
            />{" "}
          </button>{" "}
        </div>
      </div>
      <div class="mx-auto w-full max-w-screen-xl">
        <div class="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-800 uppercase dark:text-gray-800">
              Office Address
            </h2>
            <ul class="text-gray-800 dark:text-gray-400 font-medium">
              <li class="mb-4">
                <h2 className="text-sm text-gray-400">Head office:</h2>
                <p>
                  Office 6 Street 22nd block j Al rehman Garden phase lahote
                </p>
              </li>
              <li class="mb-4">
                <h2 className="text-sm text-gray-400">Branch:</h2>
                <p>
                  Office 6 Street 22nd block j Al rehman Garden phase lahote
                </p>
              </li>
              {/* <li class="mb-4">
                <p>3517 W. Gray St. Utica, Pennsylvania 57867</p>
              </li> */}
            </ul>
          </div>
          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-800 uppercase dark:text-gray-800">
              Contact Seller
            </h2>
            <ul class="text-gray-800 dark:text-gray-400 font-medium">
              <li class="mb-4">
                <div className="flex gap-5">
                  <div className="rounded-full h-14 w-14 bg-gray-400"></div>
                  <div>
                    <h2 className="text-sm text-gray-400">Darrell Steward:</h2>
                    <p>+92 (313)-4654503 </p>
                  </div>
                </div>
              </li>
              <li class="mb-4">
                <div className="flex gap-5">
                  <div className="rounded-full h-14 w-14">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-8 mx-3 mt-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>
                  </div>
                  <div>
                    <h2 className="text-sm text-gray-400"> Hotline::</h2>
                    <p>(201) 555-0124</p>
                  </div>
                </div>
              </li>
              <li class="mb-4">
                <div className="flex gap-5">
                  <div className="rounded-full h-14 w-14 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-8 mx-3 mt-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"
                      />
                    </svg>
                  </div>
                  <div className=" ">
                    <h2 className="text-sm text-gray-400">Email:</h2>
                    <span className=" text-wrap text-red-500">
                      support@wahid.pk
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-800 uppercase dark:text-gray-800">
              Our Company
            </h2>
            <ul class="text-gray-800 dark:text-gray-400 font-medium">
              <li class="mb-4">
                <a href="#" class="hover:underline">
                  Property For Sale
                </a>
              </li>
              <li class="mb-4">
                <a href="#" class="hover:underline">
                  About Us
                </a>
              </li>
              <li class="mb-4">
                <a href="#" class="hover:underline">
                  Our Agents{" "}
                </a>
              </li>
              <li class="mb-4">
                <a href="#" class="hover:underline">
                  Terms Of Use{" "}
                </a>
              </li>
              <li class="mb-4">
                <a href="#" class="hover:underline">
                  Privacy Policy{" "}
                </a>
              </li>
              <li class="mb-4">
                <a href="#" class="hover:underline">
                  Contact Us{" "}
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-800 uppercase dark:text-gray-800">
              Newsletter
            </h2>
            <ul class="text-gray-800 dark:text-gray-400 font-medium">
              <NewsletterForm />
            </ul>
          </div>
        </div>
        {/* <div class="px-4 py-6 gap-5 items-center bg-black dark:bg-gray-700 md:flex md:items-center md:justify-between">
          <span class="text-sm text-gray-500 dark:text-gray-300 sm:text-center">
            <img src={Logo} alt="logo" />
          </span>
          <div className="text-gray-800">
            <ul className="flex gap-6">
              <li>Home</li>
              <li>Property</li>
              <li>Page</li>
              <li>Blog</li>
              <li>Contact</li>
            </ul>
          </div>
          <div class="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse text-center">
            <a
              href="#"
              class="text-gray-400 hover:text-gray-900 dark:hover:text-gray-800"
            >
              <svg
                class="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 8 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Facebook page</span>
            </a>
            <a
              href="#"
              class="text-gray-400 hover:text-gray-900 dark:hover:text-gray-800"
            >
              <svg
                class="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 21 16"
              >
                <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
              </svg>
              <span class="sr-only">Discord community</span>
            </a>
            <a
              href="#"
              class="text-gray-400 hover:text-gray-900 dark:hover:text-gray-800"
            >
              <svg
                class="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 17"
              >
                <path
                  fill-rule="evenodd"
                  d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Twitter page</span>
            </a>
            <a
              href="#"
              class="text-gray-400 hover:text-gray-900 dark:hover:text-gray-800"
            >
              <svg
                class="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">GitHub account</span>
            </a>
            <a
              href="#"
              class="text-gray-400 hover:text-gray-900 dark:hover:text-gray-800"
            >
              <svg
                class="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Dribbble account</span>
            </a>
          </div>
        </div> */}
      </div>
      <div className="flex justify-center text-gray-500 text-sm bg-black py-2">
        <p className="text-center text-white flex gap-2">
          Copyright ©️ 2024 Wahid Properties (Private) Limited. Powered By
          <a className="text-sky-600" href="https://justcall.com.pk">
            JustCall
          </a>{" "}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
