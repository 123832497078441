import React from "react";
import Layout from "../../Layout/Layout";
import PricingContent from "./PricingContent";

const Pricing = () => {
  return (
    <div>
      <Layout
        pageTitle={"Wahid/Pricing"}
        pageDescription={"The Future for you and your beloved."}
        component={<PricingContent />}
      />
    </div>
  );
};

export default Pricing;
