// authActions.js
import axios from "axios";
import {
  invalidRequest,
  messageRequestLoading,
  getAllMessage,
  addMessage,
} from "../slices/message";
import { toast } from "react-toastify";
const backendURL = `${process.env.REACT_APP_BACKEND_URL_PRODUCTION}`;

export const storeMessage = (token, data) => async (dispatch) => {
  try {
    dispatch(messageRequestLoading());
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": token,
      },
    };
    await axios
      .post(`${backendURL}/messages/add-message`, data, config)
      .then((response) => {
        if (response?.data?.statusCode !== 200) {
          toast.error(response.data.message);
          return dispatch(invalidRequest(response.data.message));
        }
        dispatch(addMessage(response.data.message));
        toast.success(response.data.message);
        dispatch(getMessagesList(token));
      });
  } catch (e) {
    dispatch(invalidRequest(e.message));
  }
};
export const getMessagesList = (token) => async (dispatch) => {
  try {
    dispatch(messageRequestLoading());
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    };
    await axios
      .get(`${backendURL}/messages/get-all-messages`, config)
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        if (response?.data?.statusCode !== 200) {
          toast.error(response.data.message);
          return dispatch(invalidRequest(response.data.message));
        }
        dispatch(getAllMessage(response.data.data.messagesData));
      });
  } catch (e) {
    dispatch(invalidRequest(e.message));
  }
};
