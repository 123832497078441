// features/auth/propertySlice.js
import { createSlice } from "@reduxjs/toolkit";

export const propertySlice = createSlice({
  name: "property",
  initialState: {
    isLoading: false,
    properties: [],
    propertyDetails: {},
    message: "",
    error: "",
    type: "",
  },
  reducers: {
    propertyRequestLoading: (state, action) => {
      state.isLoading = true;
    },
    invalidRequest: (state, action) => {
      state.error = action.payload;
      state.properties = [];
      state.propertyDetails = {};
      state.message = "";
      state.isLoading = false;
      state.type = "InvalidRequest";
    },
    getAllProperties: (state, action) => {
      state.error = "";
      state.properties = action.payload;
      state.message = "success";
      state.isLoading = false;
      state.type = "success";
    },
    addProperty: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
      state.type = "success";
    },
    updatedProperty: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
      state.type = "success";
    },
    getPropertyById: (state, action) => {
      state.propertyDetails = action.payload;
      state.message = "";
      state.isLoading = false;
      state.type = "success";
      state.token = "";
    },
    deleteProperty: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
      state.type = "success";
    },
  },
});

export default propertySlice.reducer;
export const {
  propertyRequestLoading,
  getAllProperties,
  addProperty,
  updatedProperty,
  getPropertyById,
  deleteProperty,
  invalidRequest,
} = propertySlice.actions;
