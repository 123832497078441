import React from "react";
import MessageCard from "../../../../components/Card/MessageCard";
import { Heading } from "../../../../components/Heading";

const Messages = ({ messages }) => {
  return (
    <div>
      <Heading size="heading2xl" className="pb-3">
        Messages
      </Heading>
      <div>
        {messages?.map((message, index) => (
          <MessageCard key={index} message={message} />
        ))}
      </div>
    </div>
  );
};

export default Messages;
