import React from "react";
import Contents from "./Contents";
import Layout from "../../Layout/Layout";
const index = () => {
  return (
    <Layout
      pageTitle={"Wahid/Property-Details"}
      pageDescription={"The Future for you and your beloved."}
      component={<Contents />}
    />
  );
};

export default index;
