import React from "react";
import { Heading } from "../../../components/Heading";
import _ from "lodash";
import GridCard from "../../../components/Card/GridCard";
const Featured = ({ items, title, description }) => {
  return (
    <div className="lg:px-20 my-10">
      <div className="mb-10">
        <Heading title={title} size="heading5xl" className="text-center" />
        <p className="text-sm text-center">{description}</p>
      </div>
      <div className="flex gap-5 justify-center flex-wrap"></div>
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5">
        {items?.map((item, index) => (
          <GridCard item={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Featured;
