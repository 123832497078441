import React from "react";
import Sidebar from "./DashboardComponents/Sidebar";

const DashboardLayout = ({ component }) => {
  return (
    <div className="flex">
      <Sidebar component={component} />
    </div>
  );
};

export default DashboardLayout;
