import React from "react";
import IconInchTape from "../../assets/image/Icon (2).png";
import FeaturedIcon from "../../assets/image/Tagline.png";
import { Link } from "react-router-dom";
import IconBed from "../../assets/image/Icon.png";
import IconBath from "../../assets/image/Icon (1).png";
import Sale from "../../assets/image/Tagline (1).png";
import Rent from "../../assets/image/Tagline (2).png";
import Vector from "../../assets/image/Vector.png";
import _ from "lodash";
import WhatsAppButton from "../Whatsapp/WhatsappButton";
import EmailButton from "../Email/EmailButton";
import CallButton from "../Call/CallButton";
const GridCard = ({ item }) => {
  return (
    <Link to={`/property-details/${item.id}`}>
      <div className="mx-auto border border-gray-200 relative  block shadow-xl  rounded-xl bg-white w-[320px]">
        <div className=" rounded-md bg-gray-100 h-fit">
          <div className="">
            {item?.property_images?.urls?.length > 0 && (
              <img
                src={item?.property_images?.urls[0]?.url}
                alt="new"
                className="rounded-t-md h-56 w-[320px]"
              />
            )}
          </div>
          {item?.featured === 1 && (
            <div className="absolute top-3 -left-1">
              <img src={FeaturedIcon} alt="new" />
            </div>
          )}
          {item?.sale ? (
            <>
              {item?.sale === 1 && (
                <div className="absolute top-9 -left-1">
                  <img src={Sale} alt="new" />
                </div>
              )}
            </>
          ) : (
            <>
              {!item?.rent && (
                <div className="absolute top-9 -left-1">
                  <img src={Rent} alt="new" />
                </div>
              )}
            </>
          )}
          <div className="p-3">
            <div className="">
              {item?.title && (
                <h1 className="text-lg font-extrabold font-poppins	">
                  {_.toString(item?.title).slice(0, 30)}
                </h1>
              )}
              {item?.price && (
                <div className="py-2">
                  <span className=" font-extrabold font-mono text-2xl text-green-500">
                    ${item?.price}
                  </span>
                </div>
              )}
              {item?.description && (
                <div className="flex">
                  <span className="pt-1 pe-3">
                    <img src={Vector} alt="vector" />
                  </span>
                  <span className="text-sm">
                    {_.toString(item?.description).slice(0, 10)}...
                  </span>
                </div>
              )}

              <div className="flex gap-3 text-sm flex-wrap">
                {item?.bedrooms && (
                  <div className="flex ">
                    <span className="pt-1 pe-3">
                      <img src={IconBed} alt="vector" />
                    </span>
                    <span>
                      <span className="font-extrabold">{item?.bedrooms}</span>
                    </span>
                  </div>
                )}
                {item?.baths && (
                  <div className="flex">
                    <span className="pt-1 pe-3">
                      <img src={IconBath} alt="vector" />
                    </span>
                    <span>
                      <span className="font-extrabold">{item?.baths}</span>
                    </span>
                  </div>
                )}
                {item?.area && (
                  <div className="flex">
                    <span className="pt-1 pe-3">
                      <img src={IconInchTape} alt="vector" />
                    </span>
                    <span>
                      <span className="font-extrabold">
                        {" "}
                        {_.toString(item?.area).slice(0, 20)}...
                        {item?.area_type}
                      </span>
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="border border-gray-300 mt-5"></div>
            <div className="grid grid-cols-2  text-sm mt-2">
              <div className="pt-2">
                <EmailButton emailAddress={item?.user?.email} />
                <WhatsAppButton
                  phoneNumber={_.toString(item?.user?.dial_code).concat(
                    item?.user?.phone
                  )}
                />
              </div>
              <div className="pt-1 text-sx text-end">
                <CallButton
                  phoneNumber={"+".concat(
                    _.toString(item?.user?.dial_code).concat(item?.user?.phone)
                  )}
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default GridCard;
