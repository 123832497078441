import React, { useState } from "react";
import { useController, Controller } from "react-hook-form";
import _ from "lodash";
import PropTypes from "prop-types";

const Checkbox = React.forwardRef((props, ref) => {
  const { field, fieldState } = useController(props);
  const {
    title,
    style,
    type,
    errors,
    defaultValue,
    labelClass,
    disabled,
    textColor,
    ...others
  } = props;
  let err = _.get(errors, props.name);
  return (
    <div>
      <Controller
        name={props?.name}
        control={props?.control}
        rules={props?.rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <div className="">
            <label
              className={`cursor-pointer d-flex gap-x-2 items-center text-base ${
                labelClass ? labelClass : "text-black"
              }  ${props?.disabled ? "text-gray-200 cursor-not-allowed" : ""}`}
            >
              <input
                {...field}
                ref={ref}
                checked={
                  field.value == "1" || field.value === true ? true : false
                }
                type="checkbox"
                disabled={disabled}
                onChange={(e) => {
                  onChange(e.target.checked);
                  if (props.onChange) {
                    props.onChange(e);
                  }
                }}
                style={style ? style : {}}
                className={`w-[18px] h-[16px] rounded  text-[#8456bb] ${
                  props.rules && errors && err
                    ? "border-red-600"
                    : "border-gray-600"
                }  focus:shadow-none`}
              />
              <span
                className={`px-2 text-[12px] xl:text-[14px] text-${textColor}`}
              >
                {title}
              </span>
            </label>
          </div>
        )}
      />
    </div>
  );
});
Checkbox.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
  isHighLight: PropTypes.bool,
  type: PropTypes.string,
  errors: PropTypes.object,
  defaultValue: PropTypes.any,
  customStyle: PropTypes.object,
  onChange: PropTypes.func,
  ellipses: PropTypes.bool,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired, // Added 'control' prop type validation
  min: PropTypes.number, // Added 'min' prop type validation
  labelClass: PropTypes.string,
  disabled: PropTypes.bool, // Added 'isDisabled' prop type validation

  placeholder: PropTypes.string, // Added 'placeholder' prop type validation
  textColor: PropTypes.string, // Added 'textColor' prop type validation
};
export default Checkbox;
