import React from "react";
import { useForm } from "react-hook-form";
import InputField from "../../components/FormFields/components/InputField/InputField";
import Checkbox from "../../components/FormFields/components/Checkbox/Checkbox";
const NewsletterForm = () => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  return (
    <div>
      <div className="pt-5 pb-5">
        <p>Sign up to receive the latest articles</p>
      </div>
      <div className="">
        <InputField
          name="twilio_numbers"
          control={control}
          errors={errors}
          placeholder="Your email address"
          rules={{
            required: {
              value: true,
              message: "Field required!",
            },
          }}
        />
      </div>
      <div className=" py-3">
        <Checkbox
          name="read_and_agree"
          textColor="black"
          control={control}
          errors={errors}
          title="I have read and agree to the terms & conditions"
          options={[]}
          rules={{
            required: {
              value: true,
              message: "Field required!",
            },
          }}
        />
      </div>
      <button
        type="submit"
        className="flex rounded-lg w-full mt-2 justify-center bg-gray-800 px-4 py-4 text-sm font-semibold text-white shadow-sm hover:bg-gray-300 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Sign Up{" "}
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export default NewsletterForm;
