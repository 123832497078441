import React from "react";
import { Heading } from "../../components/Heading";
import { useForm } from "react-hook-form";
import InputField from "../../components/FormFields/components/InputField/InputField";
import { FaLock } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { resetPasswordApi } from "../../redux/services/auth";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { email } = useParams();
  const navigate = useNavigate();
  const handleResetPassword = async (formData) => {
    const params = {
      ...formData,
      email,
    };
    const response = await dispatch(resetPasswordApi(params));
    if (response === true) {
      navigate("/");
    }
  };
  return (
    <div className="py-20">
      <form
        className=" w-1/3 m-auto"
        onSubmit={handleSubmit(handleResetPassword)}
      >
        <Heading size="heading4xl" className="text-center">
          Reset Password
        </Heading>
        <p className="text-center">
          Add your information and you are good to go.
        </p>
        <div className=" py-5">
          <InputField
            name="password"
            type="password"
            control={control}
            svg={<FaLock />}
            errors={errors}
            placeholder="Password"
            label="Password"
            rules={{
              required: {
                value: true,
                message: "Field required!",
              },
            }}
          />
        </div>
        <div className=" py-5">
          <InputField
            name="confirmPassword"
            type="password"
            control={control}
            svg={<FaLock />}
            errors={errors}
            placeholder="Confirm Password"
            label="Confirm Password"
            rules={{
              required: {
                value: true,
                message: "Field required!",
              },
            }}
          />
        </div>

        <button
          type="submit"
          className="flex mt-5 w-full justify-center rounded-md bg-[#006aff] p-3 text-sm font-semibold text-white shadow-sm hover:bg-[#006aff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
