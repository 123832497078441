import React, { useState } from "react";
import InputField from "../FormFields/components/InputField/InputField";
import { useForm } from "react-hook-form";
import NumberInputField from "../FormFields/components/NumberInput/NumberInputField";
import { useDispatch } from "react-redux";
import { registerUser } from "../../redux/services/auth";
import _ from "lodash";
import { toast } from "react-toastify";
import Modal from "../Modal/Modal";
import OTPForm from "../OTPForm/OTPForm";
const RegisterForm = () => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleRegister = async (data) => {
    const params = {
      name: data.name,
      email: data.email,
      password: data.password,
      confirmPassword: data.confirm_password,
      phone: _.toString(data?.phone).replace(`${data.dialCode}`, ""),
      whatsapp: _.toString(data?.whatsapp).replace(`${data.dialCode}`, ""),
      dialCode: data?.dialCode,
    };
    // const closeElement = document.getElementById("close_modal");
    // console.log("🚀 ~ handleRegister ~ closeElement:", closeElement);
    // closeElement?.click();

    const registered = await dispatch(registerUser(params));

    // console.log("🚀 ~ handleRegister ~ registered:", registered);
    if (registered === true) {
      const otpModal = document.getElementById("otp_modal");
      console.log("🚀 ~ handleRegister ~ otpModal:", otpModal);
      otpModal?.click();
    }
  };
  const handleLoginClick = () => {
    const closeElement = document.getElementById("close_modal");
    closeElement?.click();
    document.getElementById("login_modal").click();
  };
  return (
    <div className="">
      <form onSubmit={handleSubmit(handleRegister)}>
        <div className="grid grid-cols-1 gap-3">
          <div className="">
            <InputField
              name="name"
              control={control}
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4 text-[#006aff]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                  />
                </svg>
              }
              errors={errors}
              placeholder="Your Full Name"
              label="Your Full Name"
              rules={{
                required: {
                  value: true,
                  message: "Field required!",
                },
              }}
            />
          </div>
          <div className="">
            <InputField
              name="email"
              control={control}
              onChange={(e) => setEmail(e.target.value)}
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4 text-[#006aff]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"
                  />
                </svg>
              }
              errors={errors}
              placeholder="Email"
              label="Email"
              rules={{
                required: {
                  value: true,
                  message: "Field required!",
                },
              }}
            />
          </div>
          <div className="">
            <NumberInputField
              name="phone"
              setValue={setValue}
              control={control}
              errors={errors}
              placeholder="Your Phone Number"
              label="Your Phone Number"
              rules={{
                required: {
                  value: true,
                  message: "Field required!",
                },
              }}
            />
          </div>
          <div className="">
            <NumberInputField
              name="whatsapp"
              setValue={setValue}
              control={control}
              errors={errors}
              placeholder="Your Whatsapp Number"
              label="Your Whatsapp Number"
              rules={{
                required: {
                  value: true,
                  message: "Field required!",
                },
              }}
            />
          </div>
          <div className="">
            <InputField
              name="password"
              type="password"
              control={control}
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4 text-[#006aff]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
              }
              errors={errors}
              placeholder="Password"
              label="Password"
              rules={{
                required: {
                  value: true,
                  message: "Field required!",
                },
              }}
            />
          </div>
          <div className="">
            <InputField
              name="confirm_password"
              control={control}
              type="password"
              svg={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4 text-[#006aff]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
              }
              errors={errors}
              placeholder="Confirm Password"
              label="Confirm Password"
              rules={{
                required: {
                  value: true,
                  message: "Field required!",
                },
              }}
            />
          </div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-xl bg-[#006aff] p-3 text-lg font-semibold text-white shadow-sm hover:bg-[#006aff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Submit
          </button>
          <div className="flex justify-center">
            <span>Already have an account?</span>
            <span className="text-[#006aff]">
              <button onClick={handleLoginClick}> Login</button>
            </span>
          </div>
        </div>
      </form>
      <Modal
        buttonID={"otp_modal"}
        title={""}
        content={<OTPForm email={email} />}
      />
    </div>
  );
};

export default RegisterForm;
