import React, { useCallback, useState } from "react";
import InputField from "../FormFields/components/InputField/InputField";
import { useForm } from "react-hook-form";
import SideImg from "../../assets/image/modalSideImage.png";
import { Link } from "react-router-dom";
import { Heading } from "../Heading";
import NumberInputField from "../FormFields/components/NumberInput/NumberInputField";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/services/auth";
import _ from "lodash";
const SignInForm = () => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const dispatch = useDispatch();
  const [loginType, setLoginType] = useState("phone");
  const handleLogin = (data) => {
    let params = {
      loginType: loginType,
      password: data?.password,
      email: loginType === "email" ? data?.email : undefined,
      phone:
        loginType === "phone"
          ? _.toString(data?.phone).replace(`${data.dialCode}`, "")
          : undefined,
      dialCode: loginType === "phone" ? data?.dialCode : undefined,
    };

    dispatch(loginUser(params));
  };
  const handleRegisterClick = () => {
    const closeElement = document.getElementById("close_modal");
    closeElement?.click();
    document.getElementById("register_modal").click();
  };
  return (
    <div className="">
      {/* <div>
        <img src={SideImg} alt="side" />
      </div> */}
      <div>
        <h1 className="font-extrabold pb-2">Login Type</h1>{" "}
        <div class="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
            onClick={() => setLoginType("email")}
          >
            Email
          </button>
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
            onClick={() => setLoginType("phone")}
          >
            Phone
          </button>
        </div>
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="grid grid-cols-1 gap-3 py-4">
            {loginType === "email" ? (
              <div className="">
                <InputField
                  name="email"
                  control={control}
                  svg={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4 text-[#006aff]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                      />
                    </svg>
                  }
                  errors={errors}
                  placeholder="Your Email"
                  label="Email Address"
                  rules={{
                    required: {
                      value: true,
                      message: "Field required!",
                    },
                  }}
                />
              </div>
            ) : (
              <div className="">
                <NumberInputField
                  name="phone"
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  placeholder="Your Phone Number"
                  label="Your Phone Number"
                  rules={{
                    required: {
                      value: true,
                      message: "Field required!",
                    },
                  }}
                />
              </div>
            )}
            <div className="">
              <InputField
                name="password"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="Password"
                label="Password"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
              <div className=" float-end text-sky-600">
                <Link to={"/forgot-password"}>Forgot Password</Link>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-xl bg-[#006aff] p-3 text-lg font-semibold text-white shadow-sm hover:bg-[#006aff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Submit
          </button>
          <div className="flex justify-center">
            <span>Already have an account?</span>
            <span className="text-[#006aff]">
              <button onClick={handleRegisterClick}>Register</button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignInForm;
