import React, { useState } from "react";
import Carousel from "../../../components/Carousel/Carousel";
import { Heading } from "../../../components/Heading";

const ContactDetails = ({ carouselImages }) => {
  return (
    <div className="flex">
      <div className="w-full rounded-lg border border-gray-300">
        <Carousel
          items={carouselImages}
          smallItems={1}
          mediumItems={1}
          largeItems={1}
          carouselType={"image"}
          autoPlay={false}
          disableDotsControls={true}
          thumbnails={true}
        />
      </div>
    </div>
  );
};
export default ContactDetails;
