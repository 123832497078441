import React from "react";
import { Heading } from "../../../components/Heading";
import LocationIcon from "../../../assets/image/location.png";
import Seperator from "../../../assets/image/seperatorLine.png";
import PhoneIcon from "../../../assets/image/phone.png";
import EmailIcon from "../../../assets/image/email.png";
const ContactDetails = () => {
  return (
    <div className="lg:flex lg:justify-end ">
      <div className="lg:w-2/3 sm:w-full border border-gray-300 rounded-xl shadow-lg px-12 pt-9 pb-10">
        {" "}
        <Heading size="heading6xl" className="font-extrabold">
          We provide the most suitable and quality real estate.
        </Heading>
        <p className="text-sm text-gray-500 pt-5">
          Discover your dream home with our user-friendly real estate platform.
          Explore a wide range of properties, from cozy apartments to luxurious
          estates, tailored to fit your lifestyle and budget.
        </p>
        <div className="pt-7 flex gap-10 flex-col">
          <div className="flex flex-wrap gap-5">
            <div className="flex">
              <img
                src={LocationIcon}
                alt="location"
                width={40}
                style={{ height: "40px" }}
              />
              <img src={Seperator} alt="seperator" />
            </div>
            <div className="pt-5 sm:w-44">
              <p className="text-xs font-bold">Office address</p>
              <p className="text-sm">
                Office 6 Street 22nd block j Al rehman Garden phase lahote{" "}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap gap-5">
            <div className="flex">
              <img
                src={PhoneIcon}
                alt="phone"
                width={40}
                style={{ height: "40px" }}
              />
              <img src={Seperator} alt="seperator" />
            </div>
            <div className="pt-5">
              <p className="text-xs font-bold">Request a call back</p>
              <p className="text-xl font-extrabold  text-[#2b8dd5]">
                +92 313 4654503{" "}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap gap-5">
            <div className="flex">
              <img
                src={PhoneIcon}
                alt="phone"
                width={40}
                style={{ height: "40px" }}
              />
              <img src={Seperator} alt="seperator" />
            </div>
            <div className="pt-5">
              <p className="text-xs font-bold">Telephone</p>
              <p className="text-xl font-extrabold  text-[#2b8dd5]">
                +92 313 4654503{" "}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap gap-5">
            <div className="flex">
              <img
                src={EmailIcon}
                alt="location"
                width={40}
                style={{ height: "40px" }}
              />
              <img src={Seperator} alt="seperator" />
            </div>
            <div className="pt-5">
              <p className="text-xs font-bold">Email us</p>
              <p className="text-sm text-[#2b8dd5]">support@wahid.pk</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
