import React from "react";
import _ from "lodash";
import Card from "../../../components/Card/ListCard";
import ListView from "../../../components/ListingView/ListView";
const ListListing = ({ items }) => {
  return (
    <div>
      <ListView items={items} />
    </div>
  );
};

export default ListListing;
