// features/auth/messageSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const messageSlice = createSlice({
  name: "message",
  initialState: {
    isLoading: false,
    messages: [],
    messageDetails: {},
    message: "",
    error: "",
    type: "",
  },
  reducers: {
    messageRequestLoading: (state, action) => {
      state.isLoading = true;
    },
    invalidRequest: (state, action) => {
      state.error = action.payload;
      state.messages = [];
      state.messageDetails = {};
      state.message = "";
      state.isLoading = false;
      state.type = "InvalidRequest";
    },
    getAllMessage: (state, action) => {
      state.error = "";
      state.messages = action.payload;
      state.message = "success";
      state.isLoading = false;
      state.type = "success";
    },
    addMessage: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
      state.type = "success";
    },
    updateMessage: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
      state.type = "success";
    },
    getMessageById: (state, action) => {
      state.messageDetails = action.payload;
      state.message = "";
      state.isLoading = false;
      state.type = "success";
      state.token = "";
    },
    deleteMessage: (state, action) => {
      state.message = action.payload;
      state.isLoading = false;
      state.type = "success";
    },
  },
});

export default messageSlice.reducer;
export const {
  messageRequestLoading,
  getAllMessage,
  addMessage,
  updateMessage,
  getMessageById,
  deleteMessage,
  invalidRequest,
} = messageSlice.actions;
