import React, { useCallback, useState } from "react";
import InputField from "../FormFields/components/InputField/InputField";
import { useForm } from "react-hook-form";
import SideImg from "../../assets/image/modalSideImage.png";
import { Link } from "react-router-dom";
import { Heading } from "../Heading";
import NumberInputField from "../FormFields/components/NumberInput/NumberInputField";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, verifyOTPApi } from "../../redux/services/auth";
import _ from "lodash";
import ReactOTPInput from "../FormFields/components/ReactOTPInput/ReactOTPInput";
const OTPForm = ({ email }) => {
  console.log("🚀 ~ OTPForm ~ email:", email);
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const dispatch = useDispatch();
  const handleOTP = async (data) => {
    const params = { email: email, otp: data?.otp };
    console.log("🚀 ~ OTPForm ~ data:", data);
    const closeElement = document.getElementById("close_modal");
    closeElement?.click();
    const is_verified = await dispatch(verifyOTPApi(params));
    if (is_verified?.data?.data?.userData) {
      dispatch(
        loginUser({
          loginType: "hashedPassword",
          email: is_verified?.data?.data?.userData?.email,
          password: is_verified?.data?.data?.userData?.password,
        })
      );
    }
  };
  return (
    <div className="">
      <div className="text-center">
        <p>
          We've mailed you 6 digits code on your email please verify your OTP.
        </p>
      </div>
      {/* <div>
        <img src={SideImg} alt="side" />
      </div> */}
      <div>
        <form onSubmit={handleSubmit(handleOTP)}>
          <div className="grid grid-cols-1 gap-3 py-4">
            <div className="">
              <ReactOTPInput
                name="otp"
                control={control}
                errors={errors}
                handleResendClick={() => console.log("Resend Otp")}
                placeholder="OTP"
                label="OTP"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
          </div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-xl bg-[#006aff] p-3 text-lg font-semibold text-white shadow-sm hover:bg-[#006aff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default OTPForm;
