import React from "react";
import HeroSearch from "../../../components/HeroSearch";
import SmallCard from "../../../components/Card/SmallCard";
import { Heading } from "../../../components/Heading";
const Sidebar = ({ items }) => {
  return (
    <div className="rounded-lg p-5 lg:w-3/4 sm:w-full	">
      <div>
        <HeroSearch
          beds={true}
          baths={true}
          price={true}
          area={true}
          property_type={true}
          priceLabel={true}
          display={"list"}
        />
      </div>
      <div className="text-center">
        <Heading size="heading3xl" title={"Featured "} className="py-5" />
        {items?.slice(0, 4)?.map((item, index) => (
          <div key={index}>
            <SmallCard item={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
