import React from "react";

import Hero from "../../components/Hero";
import Sponsered from "./components/Sponsered";
import Carousel from "../../components/Carousel/Carousel";
import Featured from "./components/Featured";
import Contact from "../Contact/Contents";
import { useSelector } from "react-redux";

const Contents = () => {
  const title1 = "Rental Properties";
  const title2 = "For Sale";
  const { properties } = useSelector((state) => state.property);

  return (
    <div>
      <Hero />
      <div className="">
        <Sponsered />
      </div>
      <div className="md:block sm:hidden">
        <Featured
          description={
            "Discover our handpicked selection of fdeatured properties, showcasing the best in real estate."
          }
          items={properties}
          title={"Featured Properties"}
        />
      </div>

      <div
        className="lg:hidden md:hidden p-4"
        style={{ backgroundAttachment: "fixed" }}
      >
        <Carousel
          items={properties}
          title={"Rental Properties"}
          inlinePadding={true}
          thumbnails={false}
          mobile={true}
          headingColor="black"
          headingPadding={true}
          description={
            "Explore a wide range of rental properties tailored to your needs with our seamless real estate platform."
          }
          disableDotsControls={true}
          disableAutoPlay={true}
          smallItems={2}
          showNavigation={false}
          disableInfinite={true}
          // background={"#006aff"}
        />
      </div>
      <div
        style={{ backgroundAttachment: "fixed", backgroundColor: "#001751" }}
      >
        <Carousel
          items={properties}
          title={title2}
          inlinePadding={true}
          thumbnails={false}
          headingPadding={true}
          showNavigation={false}
          description={
            "Find your ideal property for sale with our easy-to-use real estate platform."
          }
          // background={"#006aff"}
        />
      </div>
      <div className="sm:hidden md:block lg:block">
        <Featured items={properties} title={title1} />
      </div>
      <Contact />
    </div>
  );
};

export default Contents;
