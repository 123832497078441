import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertiesList } from "./redux/services/property";
import { getMessagesList } from "./redux/services/message";
const MainContext = createContext();
const ContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    dispatch(getPropertiesList(token));
    dispatch(getMessagesList(token));
  }, [token, dispatch]);
  return (
    <MainContext.Provider
      value={{
        modalOpen,
        setModalOpen,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
export { ContextProvider, MainContext };
