import React from "react";
import { useForm } from "react-hook-form";
import InputField from "../../components/FormFields/components/InputField/InputField";
import { Heading } from "../../components/Heading";
import { useDispatch } from "react-redux";
import { forgotPasswordApi } from "../../redux/services/auth";
import { useNavigate } from "react-router-dom";
import { LiaUserSecretSolid } from "react-icons/lia";
import { FaEnvelope } from "react-icons/fa";

const ForgotPassword = () => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleForgotPassword = async (formData) => {
    const params = {
      ...formData,
      subject: "Forgot Password OTP Verification",
    };
    const response = await dispatch(forgotPasswordApi(params));
    console.log("🚀 ~ handleForgotPassword ~ response:", response);
    if (response?.data?.data?.userData?.email) {
      navigate(`/verify-otp/${response?.data?.data?.userData?.email}`);
    }
  };
  return (
    <div className="h-screen w-screen flex justify-center py-20">
      <form className=" w-1/3 " onSubmit={handleSubmit(handleForgotPassword)}>
        <Heading size="heading4xl" className="text-center">
          Forgot Password
        </Heading>
        <p className="text-center">
          Add OTP we've mailed you on your email address
        </p>
        <div className=" py-5">
          <InputField
            name="email"
            control={control}
            svg={<FaEnvelope />}
            errors={errors}
            placeholder="Email address"
            label="Email address"
            rules={{
              required: {
                value: true,
                message: "Field required!",
              },
            }}
          />
        </div>

        <button
          type="submit"
          className="flex mt-5 w-full justify-center rounded-md bg-[#006aff] p-3 text-sm font-semibold text-white shadow-sm hover:bg-[#006aff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
