import React, { useEffect, useState } from "react";
import List from "./components/List";
import { useSelector } from "react-redux";
import _ from "lodash";

const MessagesContent = () => {
  const { user_id } = useSelector((state) => state.auth);

  const [userMessages, setUserMessages] = useState([]);

  const { messages } = useSelector((state) => state.message);
  useEffect(() => {
    if (messages?.length > 0) {
      const user_messages = messages.filter(
        (property) => property.owner_id === user_id
      );

      if (!_.isEqual(user_messages, userMessages)) {
        setUserMessages(user_messages);
      }
    }
  }, [messages, user_id, userMessages]);
  return (
    <div className="p-5">
      <List messages={userMessages} />
    </div>
  );
};

export default MessagesContent;
