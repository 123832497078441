import React from "react";
import ContactDetails from "./components/ContactDetails";
import Form from "./components/ContactForm";
const Contents = () => {
  return (
    <div>
      <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 lg:gap-5 lg:pt-20">
        <ContactDetails />
        <Form />
      </div>
    </div>
  );
};

export default Contents;
