import React from "react";
import One from "../../../assets/image/01.png";
import Two from "../../../assets/image/02.png";
import Three from "../../../assets/image/03.png";
import Four from "../../../assets/image/04.png";
const Sponsered = () => {
  return (
    <div className=" pt-16">
      <div className="text-center">Trusted by over 150+ major companies</div>
      <div className=" flex justify-center">
        <div>
          <img src={One} alt="One" />
        </div>
        <div>
          <img src={Two} alt="One" />
        </div>
        <div>
          <img src={Three} alt="One" />
        </div>
        <div>
          <img src={Four} alt="One" />
        </div>
      </div>
    </div>
  );
};

export default Sponsered;
