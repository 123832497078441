import React, { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import { Heading } from "../../../../components/Heading";
import moment from "moment";
import Pagination from "../../../../components/Pagination/Pagination";
const List = ({ properties }) => {
  const [tableListings, setTableListings] = useState([]);
  const tableHeadings = ["Listing", "Status"];

  useEffect(() => {
    const listings = [];
    properties?.map((property) => {
      listings?.push({
        id: property?.id,
        title: property?.title,
        imageUrl: property?.property_images?.urls[0]?.url,
        postedDate: moment(property?.created_at).format("DD MMM, YYYY"),
        status: property?.status,
        price: property?.price,
      });
      setTableListings(listings);
    });
  }, [properties]);
  return (
    <div>
      <Heading className="py-3 px-2 font-extrabold" size="heading2xl">
        Properties List
      </Heading>
      <Table
        tableHeadings={tableHeadings}
        tableListings={tableListings}
        property={true}
      />
    </div>
  );
};

export default List;
