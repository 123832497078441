import React, { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Properties from "./pages/Properties";
import PropertyDetails from "./pages/PropertyDetails";
import Dashboard from "./pages/User/Dashboard/Dashboard";
import DashboardProperties from "./pages/User/Properties/Properties";
import DashboardMessages from "./pages/User/Messages/Messages";
import NotFound from "./pages/NotFound/NotFound";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import VerifyOTP from "./pages/Auth/VerifyOTP";
import ResetPassword from "./pages/Auth/ResetPassword";
import Pricing from "./pages/Pricing/Pricing";
import Profile from "./pages/User/Profile/Profile";
const routes = [
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    children: [],
    breadcrumb: "Forgot Password",
  },
  {
    path: "/verify-otp/:email",
    element: <VerifyOTP />,
    children: [],
    breadcrumb: "Verify OTP",
  },
  {
    path: "/reset-password/:email",
    element: <ResetPassword />,
    children: [],
    breadcrumb: "Reset Password",
  },
  {
    path: "/",
    element: <Home />,
    children: [],
    breadcrumb: "Home",
  },
  {
    path: "/pricing",
    element: <Pricing />,
    children: [],
    breadcrumb: "Pricing",
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [],
    breadcrumb: "Dashboard",
  },
  {
    path: "/dashboard-messages",
    element: <DashboardMessages />,
    children: [],
    breadcrumb: "Messages",
  },
  {
    path: "/dashboard-properties",
    element: <DashboardProperties />,
    children: [],
    breadcrumb: "Dashboard",
  },
  {
    path: "/contact",
    element: <Contact />,
    children: [],
    breadcrumb: "Contact",
  },
  {
    path: "/profile",
    element: <Profile />,
    children: [],
    breadcrumb: "Profile",
  },
  {
    path: "/properties",
    element: <Properties />,
    children: [],
    breadcrumb: "Properties",
  },
  {
    path: "/property-details/:id",
    element: <PropertyDetails />,
    children: [],
    breadcrumb: "Property Details",
  },
  {
    path: "*",
    element: <NotFound />,
    children: [],
    breadcrumb: "Not Found",
  },
];
const router = createBrowserRouter(routes);

export default router;
