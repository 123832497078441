import React from "react";
import { FaUser } from "react-icons/fa";
const DashboardCard = ({ icon, title, value }) => {
  return (
    <div className=" border p-5 shadow-xl border-gray-300 bg-white rounded-lg  flex gap-5">
      <div className="rounded-full border border-gray-300 p-4">{icon}</div>
      <div className=" text-gray-dark">
        <div>{title}</div>
        <div className=" font-sans text-3xl font-extrabold">{value}</div>
      </div>
    </div>
  );
};

export default DashboardCard;
