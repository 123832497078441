import React, { useEffect, useMemo, useState } from "react";
import List from "./components/List";
import Add from "./components/Add";
import { LuCross } from "react-icons/lu";
import { useSelector } from "react-redux";
import _ from "lodash";

const PropertiesContent = () => {
  const [toggleForm, setToggleForm] = useState(false);
  const [userProperties, setUserProperties] = useState([]);
  const { properties } = useSelector((state) => state.property);
  const { user_id } = useSelector((state) => state.auth);
  const handleIconClick = () => {
    setToggleForm(!toggleForm);
  };
  useEffect(() => {
    if (properties?.length > 0) {
      const user_properties = properties.filter(
        (property) => property.user_id === user_id
      );

      if (!_.isEqual(user_properties, userProperties)) {
        setUserProperties(user_properties);
      }
    }
  }, [properties, user_id, userProperties]);

  return (
    <div className="p-5">
      <div
        onClick={handleIconClick}
        className="border border-gray-900 rounded-full p-2 bg-[#171818bf]"
        style={{
          transform: toggleForm ? "rotate(45deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease", // Smooth transition
          display: "inline-block", // Ensure proper rotation
        }}
      >
        <LuCross size={20} color="white" />
      </div>
      {toggleForm ? <Add /> : <List properties={userProperties} />}
    </div>
  );
};

export default PropertiesContent;
