import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import TextAreaField from "../../../../components/FormFields/components/TextAreaField/TextAreaField";
import BasicCard from "../../../../components/Card/BasicCard";
import InputField from "../../../../components/FormFields/components/InputField/InputField";
import NumberInputField from "../../../../components/FormFields/components/NumberInput/NumberInputField";

import { useDispatch, useSelector } from "react-redux";
import { updateUserRec } from "../../../../redux/services/user";
import _ from "lodash";
import { setAccount } from "./../../../../redux/slices/auth";
const Add = () => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState(null);
  const { token, user_id, user } = useSelector((state) => state.auth);
  useEffect(() => {
    setValue("name", user?.name);
    setValue("email", user?.email);
    setValue("phone", _.toString(user?.dial_code).concat(user?.phone));
    setValue("state", user?.state);
    setValue("city", user?.city);
    setValue("address", user?.address);
    setValue("zip_code", user?.zip_code);
    setValue("bio", user?.bio);
  }, [user, setValue]);
  const handleOnSubmit = async (formData) => {
    const data = new FormData();
    // Add individual fields to the FormData object
    if (avatar !== null) {
      data.append("avatar", avatar);
    }
    data.append("name", formData?.name);
    data.append("email", formData?.email);
    data.append(
      "phone",
      _.toString(formData?.phone).replace(`${formData.dialCode}`, "")
    );
    data.append("dial_code", formData?.dialCode);
    data.append("state", formData?.state);
    data.append("city", formData?.city);
    data.append("address", formData?.address);
    data.append("zip_code", formData?.zip_code);
    data.append("bio", formData?.bio);
    // Now `data` is your FormData object ready for submission
    console.log([...data.entries()]); // Debug: View the FormData content
    const updated_user = await dispatch(updateUserRec(token, user_id, data));
    console.log("🚀 ~ handleOnSubmit ~ updated_user:", updated_user);
    if (updated_user) {
      setAccount(updated_user);
    }
  };
  const handleOnChangeAvatar = (e) => {
    const file = e.currentTarget.files[0];
    setAvatar(file);
  };
  return (
    <div className="">
      <form
        className="grid grid-cols-1 gap-5"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <BasicCard heading={"Profile Photo"}>
          <div className="flex items-center space-x-6">
            <div className="shrink-0">
              <img
                className="h-28 w-28 object-cover rounded-full"
                src={
                  avatar !== null && avatar?.type !== undefined
                    ? URL.createObjectURL(avatar)
                    : user?.avatar !== undefined
                    ? user?.avatar
                    : "https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg"
                }
                alt="Current"
              />
            </div>
            <label className="">
              <span className="">Upload New Photo</span>
              <input
                type="file"
                onChange={handleOnChangeAvatar}
                className="block my-3 w-full text-sm text-slate-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-violet-50 file:text-violet-700
                hover:file:bg-violet-100`
      "
              />
            </label>
          </div>
        </BasicCard>
        <BasicCard heading={"Personal Information"}>
          <div className="grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            <div className="">
              <InputField
                name="name"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="Your Full Name"
                label="Name"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div className="">
              <InputField
                name="email"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="Email"
                label="Email"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div className="">
              <NumberInputField
                name="phone"
                setValue={setValue}
                control={control}
                errors={errors}
                placeholder="Your Phone Number"
                label="Your Phone Number"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>

            <div className="lg:col-span-3 md:col-span-3">
              <InputField
                name="address"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="Street Address"
                label="Street Address"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>

            <div className="">
              <InputField
                name="city"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="City"
                label="City"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>

            <div className="">
              <InputField
                name="state"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="State"
                label="State"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div className="">
              <InputField
                name="zip_code"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="Zip Code"
                label="Zip Code"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div className="md:col-span-3 lg:col-span-3">
              <TextAreaField
                name="bio"
                control={control}
                errors={errors}
                placeholder="Bio"
                rows={5}
                label="Bio"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
          </div>
        </BasicCard>
        <button
          type="submit"
          className=" float-end w-fit text-white bg-gradient-to-r from-[#006aff] via-[#006aff] to-[#006aff] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-[#006aff] dark:focus:ring-[#006aff] shadow-lg shadow-[#006aff] dark:shadow-lg dark:shadow-[#006aff]/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Add;
