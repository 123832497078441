"use client";

import { useContext, useEffect, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

export default function Modal({ title, content, buttonID, isOpen }) {
  const [madalOpen, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!madalOpen);
  };

  return (
    <div>
      <div>
        <button
          id={buttonID || "open_modal"}
          className="relative rounded px-2 text-black hover:text-white hover:bg-[#006aff] focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          onClick={handleToggle}
        >
          {title}
        </button>
      </div>
      <Dialog
        open={madalOpen}
        onClose={() => setOpen(false)}
        className="relative z-10"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 backdrop-blur-sm bg-[#2a282848] transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform sm:w-full sm:max-w-lg overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className=" bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className=" ">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="flex justify-between">
                      <h1 className="font-extrabold text-2xl font-sans">
                        {title}
                      </h1>
                      <button
                        id="close_modal"
                        className="border border-gray-800 rounded-md px-1"
                        onClick={handleToggle}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="mt-2">{content}</div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
