import React from "react";
import _ from "lodash";
import GridView from "../../../components/ListingView/GridView";

const GridListing = ({ items, cols }) => {
  return (
    <div className="p-5">
      <GridView items={items} cols={cols} />
    </div>
  );
};

export default GridListing;
