import React from "react";
import { useForm } from "react-hook-form";
import InputField from "../../components/FormFields/components/InputField/InputField";
import { Heading } from "../../components/Heading";
import { useDispatch } from "react-redux";
import { forgotPasswordApi, verifyOTPApi } from "../../redux/services/auth";
import { useNavigate, useParams } from "react-router-dom";
import { LiaUserSecretSolid } from "react-icons/lia";

const VerifyOTP = () => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email } = useParams();
  const handleVerifyOTP = async (formData) => {
    const params = {
      ...formData,
      email,
    };
    const response = await dispatch(verifyOTPApi(params));
    if (response?.data?.data?.otpData) {
      navigate(`/reset-password/${email}`);
    }
  };
  return (
    <div className="h-screen w-screen flex justify-center py-20">
      <form className=" w-1/3 " onSubmit={handleSubmit(handleVerifyOTP)}>
        <Heading size="heading4xl" className="text-center">
          Verify OTP
        </Heading>
        <p className="text-center">
          We've mailed you 6 digits code on your email address.
        </p>
        <div className=" py-5">
          <InputField
            name="otp"
            control={control}
            svg={<LiaUserSecretSolid />}
            errors={errors}
            placeholder="OTP"
            label="OTP"
            rules={{
              required: {
                value: true,
                message: "Field required!",
              },
            }}
          />
        </div>

        <button
          type="submit"
          className="flex mt-5 w-full justify-center rounded-md bg-[#006aff] p-3 text-sm font-semibold text-white shadow-sm hover:bg-[#006aff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default VerifyOTP;
