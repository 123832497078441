import React from "react";
const sizes = {
  heading_display:
    "text-[60px] font-bold lg:text-[60px] md:text-[52px] sm:text-[51px]",
  heading_h2:
    "text-[30px] font-bold lg:text-[30px] md:text-[20px] sm:text-[25px]",
  body_text_text_medium_b: "text-[13px] font-semibold",
  heading_h1:
    "text-[45px] font-bold lg:text-[45px] md:text-[41px] sm:text-[30px]",
  body_text_text_main_b: "text-[14px] font-semibold",
  heading_h3: "text-[18px] font-bold sm:text-[15px]",
  body_text_text_small_b: "text-[12px] font-semibold",
  heading_h6: "text-[16px] font-bold sm:text-[13px]",
  quote_large: "text-[16px] font-medium italic sm:text-[13px]",
  button_button_eb: "text-[15px] font-bold",
  textlg: "text-[6px] font-medium",
  text8xl: "text-[20px] font-medium italic sm:text-[17px]",
  headingxs: "text-[10px] font-extrabold",
  headings: "text-[10px] font-extrabold",
  headingmd: "text-[14px] font-bold",
  headinglg: "text-[16px] font-extrabold sm:text-[13px]",
  headingxl: "text-[20px] font-extrabold sm:text-[17px]",
  heading2xl: "text-[22px] font-bold lg:text-[22px] sm:text-[18px]",
  heading3xl:
    "text-[26px] font-extrabold lg:text-[26px] md:text-[24px] sm:text-[22px]",
  heading4xl:
    "text-[30px] font-extrabold lg:text-[30px] md:text-[28px] sm:text-[25px]",
  heading5xl:
    "text-[40px] font-extrabold lg:text-[40px] md:text-[38px] sm:text-[34px]",
  heading6xl:
    "text-[45px] font-extrabold lg:text-[30px] md:text-[28px] sm:text-[25px]",
  heading7xl:
    "text-[60px] font-extrabold lg:text-[60px] md:text-[52px] sm:text-[51px]",
};
const Heading = ({
  children,
  className = "",
  size = "body_text_text_main_b",
  as,
  title,
  ...restProps
}) => {
  const Component = as || "h6";
  return (
    <Component
      className={`text-text-heading  ${className} ${sizes[size]} font-serif`}
      {...restProps}
    >
      {children}
      {title}
    </Component>
  );
};

export { Heading };
