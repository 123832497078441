import React, { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import { Heading } from "../../../../components/Heading";
import moment from "moment";
const List = ({ messages }) => {
  const [tableListings, setTableListings] = useState([]);
  const tableHeadings = ["Name", "Email", "Phone", "Message"];
  useEffect(() => {
    const listings = [];
    messages?.map((message) => {
      listings?.push({
        name: message?.name,
        postedDate: moment(message?.created_at).format("DD MMM, YYYY"),
        email: message?.email,
        phone: message?.phone,
        message: message?.message,
      });
      setTableListings(listings);
    });
  }, [messages, tableListings]);
  return (
    <div>
      <Heading className="py-5 px-2 font-extrabold" size="heading2xl">
        Messages List
      </Heading>
      <Table tableHeadings={tableHeadings} tableListings={tableListings} />
    </div>
  );
};

export default List;
