import React from "react";

import Layout from "../../Layout/Layout";
import Contents from "./Contents";
const index = () => {
  return (
    <>
      <Layout
        pageTitle={"Wahid/Home"}
        pageDescription={"The Future for you and your beloved."}
        component={<Contents />}
      />
    </>
  );
};

export default index;
