import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import React, { useCallback } from "react";
import { FaArrowLeft, FaArrowRight, FaRegEnvelope } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/dashboard.png";
import LogoTypo from "../../assets/3.png";
import Navbar from "../../components/Navbar";
import { LuLayoutDashboard } from "react-icons/lu";
import { AiOutlinePropertySafety } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { TbLogout } from "react-icons/tb";
import { logoutUser } from "../../redux/services/auth";

const routes = {
  basicMenus: [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: <LuLayoutDashboard size={20} />,
    },
    {
      name: "Message",
      path: "/dashboard-messages",
      icon: <FaRegEnvelope size={20} />,
    },
    {
      name: "Properties",
      path: "/dashboard-properties",
      icon: <AiOutlinePropertySafety size={20} />,
    },
    {
      name: "Profile",
      path: "/profile",
      icon: <AiOutlinePropertySafety size={20} />,
    },
  ],
  // subMenus: [
  //   {
  //     name: "Submenu",
  //     icon: <FaEnvelope size={25} className="me-3" />,

  //     subKeys: [
  //       {
  //         name: "Dashboard",
  //         path: "/dashboard",
  //       },
  //       {
  //         name: "Prioperties",
  //         path: "/properties",
  //       },
  //       {
  //         name: "Account",
  //         path: "/account",
  //       },
  //     ],
  //   },
  // ],
};
const SidebarC = ({ component }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, token } = useSelector((state) => state.auth);

  const [collapsed, setCollapsed] = React.useState(false);
  const logout = useCallback(() => {
    dispatch(logoutUser(token));
    navigate("/");
  }, [dispatch, navigate, token]);
  return (
    <div
      className="w-screen"
      style={{ display: "flex", height: "fit", minHeight: "100vh" }}
    >
      <Sidebar
        collapsed={collapsed}
        transitionDuration={1000}
        className="px-5"
        style={{
          backgroundColor: "#22201f",
          // backgroundColor: "black",
        }}
      >
        {/* <Menu
          transitionDuration={1000}
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0 || level === 1)
                return {
                  color: active ? "#ffffff" : "#b3b3b3", // change active color
                  backgroundColor: active ? "#1abc9c" : "transparent", // change background of active menu
                  padding: "10px",
                  borderRadius: "10px", // rounded corners
                  transition: "background-color 0.3s ease", // smooth transition
                };
            },
          }}
        > */}
        <Menu
          transitionDuration={1000}
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              const commonStyles = {
                color: active ? "#ffffff" : "#b3b3b3", // change active color
                padding: "10px",
                borderRadius: "10px", // rounded corners
                transition: "background-color 0.3s ease, color 0.3s ease", // smooth transition
              };

              return {
                ...commonStyles,
                backgroundColor: active ? "#1abc9c" : "transparent", // keep active background
                "&:hover": {
                  backgroundColor: "red", // keep background on hover if active
                  color: "green", // keep text color white on hover
                  // color: active ? "#ffffff" : "#b3b3b3", // change active color
                  padding: "10px",
                  borderRadius: "10px", // rounded corners
                  transition: "background-color 0.3s ease, color 0.3s ease", // smooth transition
                },
              };
            },
          }}
        >
          <MenuItem
            className="py-1"
            icon={<img width={55} src={Logo} alt="logo" />}
          >
            <img
              src={LogoTypo}
              alt="logo"
              className="w-[190px] mx-auto bg-slate-100 rounded"
            />
          </MenuItem>
          <p className=" text-gray-500">Account</p>

          <MenuItem
            className="py-1"
            icon={
              <img
                className="rounded-full w-[40px] h-[40px] shadow-xl border border-gray-50"
                src={
                  "https://t4.ftcdn.net/jpg/05/71/83/47/360_F_571834789_ujYbUnH190iUokdDhZq7GXeTBRgqYVwa.jpg"
                }
                alt="logo"
              />
            }
          >
            <div className="text-xs">
              <p className="ps-2">{_.toString(user?.name).slice(0, 14)}</p>
              {/* <p className="ps-2">{user?.email}</p> */}
            </div>
          </MenuItem>
          <p className=" text-gray-500">Menu</p>

          {routes?.subMenus?.map((route, index) => (
            <SubMenu
              className="text-sm"
              key={index}
              defaultOpen={false}
              label={route?.name}
              icon={route?.icon}
            >
              {route?.subKeys?.map((key, index) => (
                <MenuItem className="ms-10 bg-[#3b3635]">
                  <Link className="text-sm font-extrabold px-3 " to={key?.path}>
                    {key?.name}
                  </Link>
                </MenuItem>
              ))}
            </SubMenu>
          ))}

          {routes?.basicMenus?.map((route, index) => (
            <Link to={route?.path} className="text-sm font-extrabold">
              <MenuItem
                key={index}
                className={`py-1 `}
                active={location?.pathname === route?.path ? true : false}
                icon={React.cloneElement(route.icon, {
                  color:
                    location.pathname === route.path ? "#ffffff" : "#484646",
                })} // Conditionally set icon color
              >
                <span className="ps-3">{route?.name}</span>
              </MenuItem>
            </Link>
          ))}
          <Link className="text-sm font-extrabold" onClick={logout}>
            <MenuItem
              className={`py-1 `}
              icon={<TbLogout size={20} />} // Conditionally set icon color
            >
              <span className="ps-3">Logout</span>
            </MenuItem>
          </Link>
        </Menu>
      </Sidebar>
      <div className="w-full">
        <main className="bg-[#e4efe42f]">
          <div>
            <div className="absolute top-3">
              <button
                className="border border-gray-500 p-2 rounded-full"
                onClick={() => setCollapsed(!collapsed)}
              >
                {collapsed ? <FaArrowRight /> : <FaArrowLeft />}
              </button>
            </div>
            <div className="w-full">
              <Navbar showBrand={false} />
            </div>
          </div>
          <div>{component}</div>
        </main>
      </div>
    </div>
  );
};

export default SidebarC;
