import React from "react";
import { Heading } from "../Heading";

const BasicCard = ({ children, heading, className }) => {
  return (
    <div className={className}>
      <div class="block max-w-full p-6 bg-white border border-gray-200 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700 ">
        <Heading className="font-extrabold text-lg pb-2">{heading}</Heading>
        {children}
      </div>
    </div>
  );
};

export default BasicCard;
