import moment from "moment";
import React from "react";

const MessageCard = ({ message }) => {
  return (
    <div className="max-w-[400px] border border-gray-200 p-5">
      <div>
        <div class="flex items-center">
          <img
            class="w-10 h-10 rounded-full mr-4"
            src={
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXb4pT4uOsvRQYT4H9MI9TwfkMAMRHXWscAw&s"
            }
            alt="Avatar of Jonathan Reinink"
          />
          <div class="text-sm">
            <p class="text-gray-900 leading-none">{message?.name}</p>
            <p class="text-gray-600">
              {moment(message?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
        </div>
        <div className="pt-2">{message?.message} </div>
      </div>
    </div>
  );
};

export default MessageCard;
