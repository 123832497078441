import React, { useEffect, useState } from "react";
import ContactDetails from "./components/ContactDetails";
import Form from "./components/ContactForm";
import PropertyDetails from "./components/PropertyDetails/PropertyDetails";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
const Contents = () => {
  const { id } = useParams();
  const { properties } = useSelector((state) => state.property);
  const [propertyDetails, setPropertyDetails] = useState({});
  useEffect(() => {
    if (properties?.length > 0) {
      const filteredProperty = properties?.filter(
        (property) => property?.id === _.toInteger(id)
      )[0];
      setPropertyDetails(filteredProperty);
    }
  }, [id, properties]);
  return (
    <div>
      <div className="lg:flex md:flex  lg:gap-5 py-10 lg:px-32">
        <div className="lg:w-2/3 sm:w-full">
          <ContactDetails
            carouselImages={propertyDetails?.property_images?.urls}
          />
          <div>
            <PropertyDetails propertyDetails={propertyDetails} />
          </div>
        </div>
        <div className="lg:w-1/3 sm:w-full">
          <Form property={propertyDetails} />
        </div>
      </div>
    </div>
  );
};

export default Contents;
