import React, { useEffect, useMemo, useState } from "react";
import Add from "./components/Add";
import { useSelector } from "react-redux";
import _ from "lodash";

const ProfileContent = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="p-5">
      <Add user={user} />
    </div>
  );
};

export default ProfileContent;
