import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReactSelectField from "../FormFields/components/ReactSelectField/ReactSelectField";
import { Heading } from "../Heading";
import { FaArrowDown, FaArrowUp, FaDollarSign } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MainSearch = ({
  display,
  beds,
  baths,
  price,
  area,
  margin,
  priceLabel,
  property_type,
}) => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const [label, setLabel] = useState("buy");
  const [show, setShow] = useState("less");
  const area_typeWatcher = watch("area_type");
  const handleSearchSubmit = (data) => {
    navigate(
      `/properties?city=${data?.city?.value}&area=${data?.area?.value}&range=${data?.maximum_range?.value}&type=${data?.property_type?.value}&location=${data?.location?.value}&label=${label}`
    );
  };
  return (
    <form
      className=" bg-transparent"
      onSubmit={handleSubmit(handleSearchSubmit)}
    >
      <div className="flex justify-center">
        <div className="flex">
          <button
            type="button"
            className={`flex px-10 rounded-s-md ${
              label === "buy" ? "bg-[#006aff]" : "bg-[#006aff4f]"
            } py-2 text-lg font-extrabold text-white shadow-sm hover:bg-[#006aff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            onClick={() => setLabel("buy")}
          >
            Buy
          </button>
          <button
            type="button"
            className={`flex px-10 rounded-e-md ${
              label === "rent" ? "bg-[#006aff]" : "bg-[#006aff4f]"
            } py-2 text-lg font-extrabold text-white bold shadow-sm hover:bg-[#006aff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            onClick={() => setLabel("rent")}
          >
            Rent
          </button>
        </div>
      </div>
      <div
        className={`${margin ? "lg:mx-40" : ""} 
        shadow-xl rounded-xl grid lg:grid-cols-${
          display === "grid" ? "4" : "1"
        } lg:flex-${display} gap-4 bg-gray-50 border border-gray-300  p-3 rounded-md`}
      >
        <div className={`${display === "list" ? "" : ""}`}>
          <ReactSelectField
            name="city"
            control={control}
            errors={errors}
            placeholder="City"
            options={[{ label: "Lahore", value: "Lahore" }]}
            rules={{
              required: {
                value: true,
                message: "Field required!",
              },
            }}
          />
        </div>
        {property_type && (
          <div className={`${display === "list" ? "" : ""}`}>
            <ReactSelectField
              name="property_type"
              control={control}
              errors={errors}
              placeholder="Property Type"
              options={[{ label: "Home", value: "home" }]}
              rules={{
                required: {
                  value: true,
                  message: "Field required!",
                },
              }}
            />
          </div>
        )}
        <div
          className={`${
            display === "list"
              ? ""
              : `${
                  display === "grid" && show === "more"
                    ? "lg:col-span-3"
                    : display === "grid" && "lg:col-span-2"
                }`
          }`}
        >
          <ReactSelectField
            name="location"
            control={control}
            errors={errors}
            placeholder="Location"
            options={[{ label: "Gulberg", value: "gulberg" }]}
            rules={{
              required: {
                value: true,
                message: "Field required!",
              },
            }}
          />
        </div>
        {baths && (
          <div className={`${display === "list" ? "" : ""}`}>
            <ReactSelectField
              name="baths"
              control={control}
              errors={errors}
              placeholder="Baths"
              options={[]}
              rules={{
                required: {
                  value: true,
                  message: "Field required!",
                },
              }}
            />
          </div>
        )}
        {beds && display === "list" && (
          <div className={`${display === "list" ? "" : ""}`}>
            <ReactSelectField
              name="beds"
              control={control}
              errors={errors}
              placeholder="Beds"
              options={[]}
              rules={{
                required: {
                  value: true,
                  message: "Field required!",
                },
              }}
            />
          </div>
        )}
        {(price || show === "more") && (
          <div
            className={`${
              display === "list"
                ? ""
                : `${display === "grid" && "lg:col-span-1"}`
            }`}
          >
            {/* {priceLabel && (
              <div>
                <Heading size="headingxl" title="Price" />
              </div>
            )} */}
            <div
              className={`grid ${display === "list" && "grid-cols-2"} gap-3`}
            >
              {display === "list" && (
                <div className={`${display === "list" ? "" : ""}`}>
                  <ReactSelectField
                    name="price_min"
                    control={control}
                    errors={errors}
                    placeholder="Price Min"
                    options={[{ label: "5 Lakh", value: "5000000" }]}
                    rules={{
                      required: {
                        value: true,
                        message: "Field required!",
                      },
                    }}
                  />
                </div>
              )}
              <div className={`${display === "list" ? "" : ""}`}>
                <ReactSelectField
                  name="max"
                  control={control}
                  errors={errors}
                  placeholder="Price Max"
                  options={[{ label: "5 Lakh", value: "5000000" }]}
                  rules={{
                    required: {
                      value: true,
                      message: "Field required!",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {(area || show === "more") && (
          <>
            <div className={`${display === "list" ? "" : ""}`}>
              <ReactSelectField
                name="area_type"
                control={control}
                errors={errors}
                placeholder="Area Unit"
                options={[
                  {
                    label: "Sqft",
                    value: "sqft",
                  },
                  {
                    label: "Acre",
                    value: "acre",
                  },
                  {
                    label: "Canal",
                    value: "canal",
                  },
                  {
                    label: "Marla",
                    value: "marla",
                  },
                  {
                    label: "Square Meter",
                    value: "square meter",
                  },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div
              className={`${
                display === "list"
                  ? ""
                  : `${display === "grid" && "lg:col-span-1"}`
              }`}
            >
              {display === "list" && (
                <div>
                  <Heading size="headingxl" title="Area" />
                </div>
              )}

              <div
                className={`grid ${display === "list" && "grid-cols-2"} gap-3`}
              >
                {display === "list" && (
                  <div className={`${display === "list" ? "" : ""}`}>
                    <ReactSelectField
                      name="area_min"
                      control={control}
                      errors={errors}
                      placeholder={`Area Min ${
                        area_typeWatcher !== undefined
                          ? area_typeWatcher?.value
                          : ""
                      }`}
                      options={[
                        {
                          label: "1",
                          value: "1",
                        },
                        {
                          label: "2",
                          value: "2",
                        },
                        {
                          label: "3",
                          value: "3",
                        },
                        {
                          label: "4",
                          value: "4",
                        },
                        {
                          label: "5",
                          value: "5",
                        },
                      ]}
                      rules={{
                        required: {
                          value: true,
                          message: "Field required!",
                        },
                      }}
                    />
                  </div>
                )}
                <div className={`${display === "list" ? "" : ""}`}>
                  <ReactSelectField
                    name="area_max"
                    control={control}
                    errors={errors}
                    placeholder={`Area Max ${
                      area_typeWatcher !== undefined
                        ? area_typeWatcher?.value
                        : ""
                    }`}
                    options={[
                      {
                        label: "1",
                        value: "1",
                      },
                      {
                        label: "2",
                        value: "2",
                      },
                      {
                        label: "3",
                        value: "3",
                      },
                      {
                        label: "4",
                        value: "4",
                      },
                      {
                        label: "5",
                        value: "5",
                      },
                    ]}
                    rules={{
                      required: {
                        value: true,
                        message: "Field required!",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <button
          type="submit"
          class="px-6  justify-center gap-5 py-3 text-base font-extrabold text-white inline-flex items-center bg-[#006aff] hover:bg-[#006aff] focus:ring-4 focus:outline-none focus:ring-[#006aff] rounded-lg text-center dark:bg-[#006aff] dark:hover:bg-[#006aff] dark:focus:ring-[#006aff]"
        >
          <span className="">Search Now</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        </button>
      </div>
      {display === "grid" && (
        <div className={`px-5 py-2 ${margin ? "lg:mx-40" : ""} `}>
          {show === "more" && (
            <button
              type="button"
              className="text-xs flex gap-1 text-blue"
              onClick={() => setShow("less")}
            >
              <span> Less Option</span>
              <span className="pt-1">
                <FaArrowUp />
              </span>
            </button>
          )}
          {show === "less" && (
            <button
              type="button"
              className="text-xs flex gap-1 text-blue"
              onClick={() => setShow("more")}
            >
              <span> More Option</span>
              <span className="pt-1">
                <FaArrowDown />
              </span>
            </button>
          )}
        </div>
      )}
    </form>
  );
};

export default MainSearch;
