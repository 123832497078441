import React from "react";
import Listing from "./components/Listing";
import Sidebar from "./components/Sidebar";
import Hero from "../../components/Hero/index";
import { useSelector } from "react-redux";

const Contents = () => {
  const { properties } = useSelector((state) => state.property);

  return (
    <div>
      {/* <Hero /> */}

      <div className="flex flex-wrap xl:justify-end lg:justify-center md:justify-center sm:justify-center">
        <div className=" w-fit ">
          <Listing items={properties} />
        </div>{" "}
        <div className="lg:w-1/3 sm:w-full ">
          <Sidebar items={properties} />
        </div>
      </div>
    </div>
  );
};

export default Contents;
