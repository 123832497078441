import React from "react";
import { useForm } from "react-hook-form";
import InputField from "../../../components/FormFields/components/InputField/InputField";
import ReactSelectField from "../../../components/FormFields/components/ReactSelectField/ReactSelectField";
import TextAreaField from "../../../components/FormFields/components/TextAreaField/TextAreaField";
import { Heading } from "../../../components/Heading";
import ContactVector from "../../../assets/image/contact_vector.png";
import { useDispatch, useSelector } from "react-redux";
import { contactUsRec } from "../../../redux/services/auth";
const ContactForm = () => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const handleContactUs = (formData) => {
    dispatch(contactUsRec(token, formData));
  };
  return (
    <form
      onSubmit={handleSubmit(handleContactUs)}
      className="rounded-xl border border-gray-300 shadow-lg p-5 lg:w-2/3 sm:w-full	"
    >
      <div className="flex justify-between my-5">
        <div>
          <Heading size="heading2xl">Contact Us</Heading>
          <p className="text-sm text-gray-600">
            We will respond as soon as we receive your message.
          </p>
        </div>
        <div>
          <img src={ContactVector} alt="contact" />
        </div>
      </div>
      <div className=" py-5">
        <InputField
          name="name"
          control={control}
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4 text-[#2b8dd5]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>
          }
          errors={errors}
          placeholder="Your Name"
          label="Your Name"
          rules={{
            required: {
              value: true,
              message: "Field required!",
            },
          }}
        />
      </div>
      <div className="grid grid-cols-2 gap-5 mb-5">
        <div className="">
          <InputField
            name="email"
            control={control}
            errors={errors}
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 text-[#2b8dd5]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"
                />
              </svg>
            }
            placeholder="Email"
            label="Email"
            rules={{
              required: {
                value: true,
                message: "Field required!",
              },
            }}
          />
        </div>
        <div className="">
          <InputField
            name="phone_number"
            control={control}
            errors={errors}
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 text-[#2b8dd5]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                />
              </svg>
            }
            placeholder="Phone"
            label="Phone"
            rules={{
              required: {
                value: true,
                message: "Field required!",
              },
            }}
          />
        </div>
      </div>
      <div>
        <TextAreaField
          name="message"
          control={control}
          errors={errors}
          placeholder="Your Message"
          rows={7}
          label="Your Message"
          options={[]}
          rules={{
            required: {
              value: true,
              message: "Field required!",
            },
          }}
        />
      </div>
      <button
        type="submit"
        className="flex mt-5 rounded-md bg-[#2b8dd5] p-3 text-sm font-semibold text-white shadow-sm hover:bg-[#2b8dd5] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
