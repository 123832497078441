import React from "react";
import heroImage from "./../../assets/image/Img.png";
import HeroSearch from "../HeroSearch";

const index = () => {
  return (
    <div className="relative border border-gray-200 shadow-md">
      {/* <div
        className="relative px-0 pt-0 max-h-content lg:px-0"
        style={{
          background:
            "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,169,32,0.4976584383753502) 64%, rgba(255,255,255,1) 96%)",
        }}
      >
        <div
          className="relative bottom-0"
          style={{
            // backgroundImage: `url(${heroImage})`,
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "right",
            backgroundSize: "35% 70%",
          }}
        >
          <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#006aff] to-[#FFFFFF] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
          </div>
          <div className="mx-auto max-w-7xl py-32 sm:py-48 lg:py-56">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight text-gray-900 sm:text-6xl font-extrabold font-sans">
                We will find a perfect home for you
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Find a variety of properties that suit you very easily, forget
                all difficulties in finding a residence for you
              </p>
            </div>
          </div>

          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>
        </div>
      </div> */}
      {/* bg-[url('https://www.zillowstatic.com/bedrock/app/uploads/sites/5/2024/07/image2-xl%402x.jpg')] */}

      {/* bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] */}
      <section
        class="bg-gradient-to-r from-[#006aff20] from-15% via-white via-30% to-white to-90% dark:bg-gray-900 
       dark:bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]"
      >
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
          <a
            href="#"
            class="inline-flex justify-between items-center py-1 px-1 pe-4 mb-7 text-sm text-blue-700 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300 hover:bg-blue-200 dark:hover:bg-blue-800"
          >
            <span class="text-xs bg-blue-600 rounded-full text-white px-4 py-1.5 me-3">
              Welcome!
            </span>{" "}
            <span class="text-sm font-medium animate-bounce">
              Apkay Khwabon Ki Wahid Tabee{" "}
            </span>
            <svg
              class="w-2.5 h-2.5 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </a>
          <h1 class="mb-4 py-5 text-4xl font-extrabold tracking-tight font-serif leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            Let Wahid Build Your Business
          </h1>
          <HeroSearch display={"grid"} margin={true} />
        </div>
        <div class="bg-gradient-to-b from-blue-50 to-transparent dark:from-blue-900 w-full h-full absolute top-0 left-0 z-0"></div>
      </section>
    </div>
  );
};

export default index;
