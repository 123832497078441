import React from "react";
import { Heading } from "../../../../components/Heading";
import { useForm } from "react-hook-form";
import FileField from "../../../../components/FormFields/components/FileField/FileField";
import TextAreaField from "../../../../components/FormFields/components/TextAreaField/TextAreaField";
import BasicCard from "../../../../components/Card/BasicCard";
import InputField from "../../../../components/FormFields/components/InputField/InputField";
import { FaDollarSign } from "react-icons/fa";
import RadioField from "../../../../components/FormFields/components/RadioField/RadioField";
import ReactSelectField from "../../../../components/FormFields/components/ReactSelectField/ReactSelectField";
import { BsTextarea } from "react-icons/bs";
import Checkbox from "../../../../components/FormFields/components/checkboxField";
import { useDispatch, useSelector } from "react-redux";
import { storeProperty } from "../../../../redux/services/property";

const amenities = [
  {
    category: "Outdoor features",
    amenities: [
      {
        name: "Balcony",
        key: "balcony",
      },
      {
        name: "Garage",
        key: "garage",
      },
      {
        name: "Shed",
        key: "shed",
      },
      {
        name: "Outdoor area",
        key: "outdoor_area",
      },
      {
        name: "Underwater parking",
        key: "parking",
      },
    ],
  },
  {
    category: "Indoor features",
    amenities: [
      {
        name: "Dishwasher",
        key: "diswasher",
      },
      {
        name: "Study",
        key: "study",
      },
      {
        name: "Gym",
        key: "gym",
      },
    ],
  },
  {
    category: "Climate Control",
    amenities: [
      {
        name: "Air Conditioning",
        key: "ac",
      },
      {
        name: "Heater",
        key: "heater",
      },
      {
        name: "Solar Panels",
        key: "solar_panels",
      },
    ],
  },
];

const Add = () => {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const dispatch = useDispatch();
  const { token, user_id } = useSelector((state) => state.auth);
  const labelWatcher = watch("label");
  const ariaTypeWatcher = watch("area_type");
  const handleOnSubmit = (formData) => {
    // Resulting object for selected amenities

    const data = new FormData();

    // Add individual fields to the FormData object
    data.append("user_id", user_id);
    data.append("category_id", 1);
    data.append("title", formData.title);
    data.append("type", "buy");
    data.append("description", formData.description);
    data.append("location", formData.address);
    data.append("label", formData.label);
    data.append("city", formData.city);
    data.append("zip_code", formData.zip_code);
    data.append("state", formData.state);
    data.append("area", formData.area);
    data.append("area_type", formData.area_type);
    data.append(
      "late_charges_per_day",
      formData.late_charges_per_day || "0.00"
    );
    data.append("min_down_payment", formData.min_down_payment || "0.00");
    data.append("price", formData.price || "0.00");
    data.append(
      "rent_price_per_month",
      formData.rent_price_per_month || "0.00"
    );

    // Add file if it exists
    if (formData?.myFile && formData?.myFile?.length > 0) {
      Array.from(formData?.myFile)?.forEach((file) => {
        data.append("property_images", file);
      });
    }

    // Serialize and append selected amenities
    const selectedAmenities = {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] === true) {
        selectedAmenities[key] = true;
      }
    });
    const result = Object.entries(selectedAmenities).map(([key, value]) => key);
    const amenetiesArray = {
      main_features: result,
    };
    console.log(amenetiesArray);

    data.append("amenities", JSON.stringify(amenetiesArray));

    // Add complex objects (like bedrooms, garages, etc.)
    data.append("bedrooms", formData.bedrooms.value);
    data.append("baths", formData.baths.value);
    data.append("garages", formData.garages.value);
    data.append("total_rooms", formData.total_rooms.value);

    // Now `data` is your FormData object ready for submission
    console.log([...data.entries()]); // Debug: View the FormData content
    dispatch(storeProperty(token, data));
  };
  return (
    <div className="">
      <form
        className="grid grid-cols-1 gap-5"
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <BasicCard heading={"Upload Phone"}>
          <div>
            <FileField
              name="myFile"
              //   label="Upload Photos"
              control={control}
              rules={{ required: "File is required" }}
              multiple={true}
            />
          </div>
        </BasicCard>
        <BasicCard heading={"Information"}>
          <div className="grid gap-5 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
            <div className=" py-5">
              <InputField
                name="title"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="Give a fine title to this property"
                label="Title"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div className=" py-5">
              <InputField
                name="address"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="Street Address"
                label="Street Address"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
          </div>
          <div className="grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            <div className=" py-5">
              <InputField
                name="city"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="City"
                label="City"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div className=" py-5">
              <InputField
                name="zip_code"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="Postal Code"
                label="Postal Code"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div className=" py-5">
              <InputField
                name="state"
                control={control}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4 text-[#006aff]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                }
                errors={errors}
                placeholder="State"
                label="State"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
          </div>
          <div>
            <TextAreaField
              name="description"
              control={control}
              errors={errors}
              placeholder="Description"
              rows={5}
              label="Description"
              rules={{
                required: {
                  value: true,
                  message: "Field required!",
                },
              }}
            />
          </div>
        </BasicCard>
        <BasicCard heading={"Price And Duration"}>
          <Heading className="font-extrabold text-lg">Label</Heading>
          <div className="flex gap-5 flex-wrap">
            <div className="py-5">
              <RadioField
                name="label"
                title="Rent"
                value="rent"
                control={control}
                errors={errors}
                placeholder="Late Charges Per Day"
                label="Late Charges Per Day"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div className=" py-5">
              <RadioField
                name="label"
                title="Buy"
                control={control}
                value="buy"
                errors={errors}
                placeholder="Late Charges Per Day"
                label="Late Charges Per Day"
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
          </div>
          {labelWatcher === "rent" ? (
            <div className="grid gap-5 lg:grid-cols-2 sm:grid-cols-1">
              <div className=" py-5">
                <InputField
                  name="rent_price_per_month"
                  control={control}
                  errors={errors}
                  placeholder="Rent Price Per Month"
                  label="Rent Price Per Month"
                  rules={{
                    required: {
                      value: true,
                      message: "Field required!",
                    },
                  }}
                />
              </div>
              <div className=" py-5">
                <InputField
                  name="late_charges_per_day"
                  control={control}
                  errors={errors}
                  placeholder="Late Charges Per Day"
                  label="Late Charges Per Day"
                  rules={{
                    required: {
                      value: true,
                      message: "Field required!",
                    },
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="grid gap-5 lg:grid-cols-2 sm:grid-cols-1">
              <div className=" py-5">
                <InputField
                  name="price"
                  control={control}
                  // svg={<FaDollarSign />}
                  errors={errors}
                  placeholder="Price"
                  label="Price"
                  rules={{
                    required: {
                      value: true,
                      message: "Field required!",
                    },
                  }}
                />
              </div>
              <div className=" py-5">
                <InputField
                  name="min_down_payment"
                  control={control}
                  // svg={<FaDollarSign />}
                  errors={errors}
                  placeholder="Min Down Payment"
                  label="Min Down Payment"
                  rules={{
                    required: {
                      value: true,
                      message: "Field required!",
                    },
                  }}
                />
              </div>
            </div>
          )}
        </BasicCard>
        <BasicCard heading={"Additional Information"}>
          <div className="grid gap-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
            <div>
              <ReactSelectField
                name="bedrooms"
                label="Bedrooms"
                control={control}
                errors={errors}
                placeholder="Property Type"
                options={[
                  {
                    label: "1",
                    value: "1",
                  },
                  {
                    label: "2",
                    value: "2",
                  },
                  {
                    label: "3",
                    value: "3",
                  },
                  {
                    label: "4",
                    value: "4",
                  },
                  {
                    label: "5",
                    value: "5",
                  },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div>
              <ReactSelectField
                name="baths"
                label="Baths"
                control={control}
                errors={errors}
                placeholder="Property Type"
                options={[
                  {
                    label: "1",
                    value: "1",
                  },
                  {
                    label: "2",
                    value: "2",
                  },
                  {
                    label: "3",
                    value: "3",
                  },
                  {
                    label: "4",
                    value: "4",
                  },
                  {
                    label: "5",
                    value: "5",
                  },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div>
              <ReactSelectField
                name="total_rooms"
                label="Rooms"
                control={control}
                errors={errors}
                placeholder="Property Type"
                options={[
                  {
                    label: "1",
                    value: "1",
                  },
                  {
                    label: "2",
                    value: "2",
                  },
                  {
                    label: "3",
                    value: "3",
                  },
                  {
                    label: "4",
                    value: "4",
                  },
                  {
                    label: "5",
                    value: "5",
                  },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div>
              <ReactSelectField
                name="garages"
                label="Garages"
                control={control}
                errors={errors}
                placeholder="Garages"
                options={[
                  {
                    label: "1",
                    value: "1",
                  },
                  {
                    label: "2",
                    value: "2",
                  },
                  {
                    label: "3",
                    value: "3",
                  },
                  {
                    label: "4",
                    value: "4",
                  },
                  {
                    label: "5",
                    value: "5",
                  },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div className="py-5">
              <ReactSelectField
                name="area_type"
                label="Area Type"
                control={control}
                errors={errors}
                placeholder="Area Type"
                options={[
                  {
                    label: "Sqft",
                    value: "sqft",
                  },
                  {
                    label: "Acre",
                    value: "acre",
                  },
                  {
                    label: "Canal",
                    value: "canal",
                  },
                  {
                    label: "Marla",
                    value: "marla",
                  },
                  {
                    label: "Square Meter",
                    value: "square meter",
                  },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
            <div className="py-5">
              <InputField
                name="area"
                label={`Area ${
                  ariaTypeWatcher !== undefined ? ariaTypeWatcher?.value : ""
                }`}
                control={control}
                errors={errors}
                svg={<BsTextarea />}
                placeholder={`Area ${
                  ariaTypeWatcher !== undefined ? ariaTypeWatcher?.value : ""
                }`}
                rules={{
                  required: {
                    value: true,
                    message: "Field required!",
                  },
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-5"></div>
        </BasicCard>
        <BasicCard heading={"Amenities"}>
          <div className="grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            {amenities?.map((key, index) => (
              <div key={index}>
                <Heading>{key?.category}</Heading>
                <div className="grid gap-5 lg:grid-cols-2 sm:grid-cols-1">
                  {key?.amenities?.map((amenity, index) => (
                    <Checkbox
                      name={amenity?.key}
                      label={amenity?.name}
                      control={control}
                      errors={errors}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </BasicCard>
        <button
          type="submit"
          className=" float-end w-fit text-white bg-gradient-to-r from-[#006aff] via-[#006aff] to-[#006aff] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-[#006aff] dark:focus:ring-[#006aff] shadow-lg shadow-[#006aff] dark:shadow-lg dark:shadow-[#006aff]/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Add;
