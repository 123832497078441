import React from "react";
import IconInchTape from "../../assets/image/Icon (2).png";
import FeaturedIcon from "../../assets/image/Tagline.png";
import { Link } from "react-router-dom";
import IconBed from "../../assets/image/Icon.png";
import IconBath from "../../assets/image/Icon (1).png";
import Sale from "../../assets/image/Tagline (1).png";
import Vector from "../../assets/image/Vector.png";
import _ from "lodash";
import CallButton from "../Call/CallButton";
import WhatsAppButton from "../Whatsapp/WhatsappButton";
import EmailButton from "../Email/EmailButton";

const ListCard = ({ item }) => {
  return (
    <Link
      to={`/property-details/${item.id}`}
      className=" lg:h-fit lg:w-[833px] sm:w-full "
    >
      <div className=" grid grid-cols-2 relative  ">
        <div>
          {item?.property_images?.urls?.length > 0 && (
            <img
              src={item?.property_images?.urls[0]?.url}
              alt="new"
              className=" lg:w-[400px] lg:h-[260px] sm:w-full sm:h-[150px] md:h-[200px] rounded-s-xl shadow-lg"
            />
          )}
        </div>
        <div className=" pt-3 sm:p-2 rounded-md h-fit ">
          {item?.featured === 1 && (
            <div className="absolute top-3 -left-1">
              <img src={FeaturedIcon} alt="new" />
            </div>
          )}
          {item?.sale === 1 && (
            <div className="absolute top-10 -left-1">
              <img src={Sale} alt="new" />
            </div>
          )}
          <div>
            {item?.title && (
              <h1 className="lg:text-lg md:text-md sm:text-sm font-poppins font-extrabold	lg:pb-4">
                {_.toString(item?.title).slice(0, 30)}
              </h1>
            )}
            <div className="lg:block md:hidden sm:hidden">
              {item?.description && (
                <div className="flex ">
                  <span className="pt-1 pe-3 h-[50px] overflow-hidden">
                    <img src={Vector} alt="vector" />
                  </span>
                  <span className="text-sm">
                    {_.toString(item?.description).slice(0, 70)}
                  </span>
                </div>
              )}
            </div>
            {item?.area && (
              <div className="flex text-xs">
                <span className="ppe-32">
                  <img src={IconInchTape} alt="vector" />
                </span>
                <span className="px-2">
                  <span className="font-extrabold">{item?.area}</span>{" "}
                  <span>{item?.area_type}</span>
                </span>
              </div>
            )}
            {item?.price && (
              <div className="py-2">
                <span className=" font-extrabold font-mono lg:text-2xl sm:text-md text-green-500">
                  PKR {item?.price}
                </span>
              </div>
            )}
            <div className="flex flex-wrap gap-3  text-xs">
              {item?.bedrooms && (
                <div className="flex ">
                  <span className=" pe-2">
                    <img src={IconBed} alt="vector" />
                  </span>
                  <span>
                    <span className="font-extrabold">{item?.bedrooms}</span>
                  </span>
                </div>
              )}
              {item?.baths && (
                <div className="flex">
                  <span className=" pe-2">
                    <img src={IconBath} alt="vector" />
                  </span>
                  <span>
                    <span className="font-extrabold">{item?.baths}</span>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="">
            <div className="border border-gray-300 mt-2 lg:block md:block sm:hidden"></div>
            <div className="grid grid-cols-2  text-sm mt-2">
              <div className="pt-2">
                <EmailButton emailAddress={item?.user?.email} />
                <WhatsAppButton
                  phoneNumber={_.toString(item?.user?.dial_code).concat(
                    item?.user?.phone
                  )}
                />
              </div>
              <div className="pt-2 text-sx text-end">
                <CallButton
                  phoneNumber={"+".concat(
                    _.toString(item?.user?.dial_code).concat(item?.user?.phone)
                  )}
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ListCard;
