import React, { useEffect, useState } from "react";
import DashboardCard from "../../../components/Card/DashboardCard";
import { Heading } from "./../../../components/Heading";
import { FaList, FaRegStar } from "react-icons/fa";
import ReactChart from "../../../components/Chart/ReactChart";
import Messages from "./components/Messages";
import List from "../Properties/components/List";
import { useSelector } from "react-redux";
import _ from "lodash";
import { IoTimerOutline } from "react-icons/io5";
import { MdOutlineReviews } from "react-icons/md";
import BasicCard from "../../../components/Card/BasicCard";
const DashboardContent = () => {
  const [userProperties, setUserProperties] = useState([]);
  const [userMessages, setUserMessages] = useState([]);
  const { properties } = useSelector((state) => state.property);
  const { user_id } = useSelector((state) => state.auth);
  const { messages } = useSelector((state) => state.message);
  useEffect(() => {
    if (properties?.length > 0) {
      const user_properties = properties.filter(
        (property) => property.user_id === user_id
      );

      if (!_.isEqual(user_properties, userProperties)) {
        setUserProperties(user_properties);
      }
    }
  }, [properties, user_id, userProperties]);
  useEffect(() => {
    if (messages?.length > 0) {
      const user_messages = messages.filter(
        (property) => property.user_id === user_id
      );

      if (!_.isEqual(user_messages, userMessages)) {
        setUserMessages(user_messages);
      }
    }
  }, [messages, user_id, userMessages]);
  return (
    <div className="p-5">
      <div className="pb-5">
        <Heading size="heading4xl" className="">
          Dashboard
        </Heading>
      </div>
      <div className="grid gap-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
        <DashboardCard
          icon={<FaList size={35} />}
          title={"Your Listing"}
          value={userProperties?.length}
        />
        <DashboardCard
          icon={<IoTimerOutline size={35} />}
          title={"Pending"}
          value={userProperties?.length}
        />
        <DashboardCard
          icon={<FaRegStar size={35} />}
          title={"Favourite"}
          value={userProperties?.length}
        />
        <DashboardCard
          icon={<MdOutlineReviews size={35} />}
          title={"Reviews"}
          value={"0"}
        />
        <BasicCard className="lg:col-span-3 ">
          <List properties={userProperties} />
        </BasicCard>
        <BasicCard>
          <Messages messages={userMessages} />
        </BasicCard>
        <BasicCard className="lg:col-span-4">
          <div className="pb-5">
            <Heading size="heading2xl" className="">
              Page Insight
            </Heading>
          </div>
          <ReactChart />
        </BasicCard>
      </div>
    </div>
  );
};

export default DashboardContent;
